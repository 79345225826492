import { noop } from '@common';
import { Column, Row, RowItemsAlignment } from '@components';
import { useClickOutside } from '@hooks';
import React, { useEffect, useRef, useState } from 'react';
import s from './ComplexFilter.scss';
import cn from 'classnames';
import { IComplexFilters } from '@hooks/workbench/Streamers';

interface ComplexFilterProps {
    daTypesValues?: Array<string>;
    scouts?: Array<string>;
    statesValues?: Array<string>;
    typesValues?: Array<string>;
    onFilterChange?: (filters: IComplexFilters) => void;
}

export const ComplexFilter = (props: ComplexFilterProps) => {
    const {
        daTypesValues = [],
        scouts = [],
        statesValues = [],
        typesValues = [],
        onFilterChange = noop,
    } = props;

    const [isDropDownShown, setIsDropDownShown] = useState(false);
    const [filteredStreamerStates, setFilteredStreamerStates] = useState<Array<number>>([]);
    const [filteredStreamerTypes, setFilteredStreamerTypes] = useState<Array<number>>([]);
    const [filteredDaTypes, setFilteredDaTypes] = useState<Array<number>>([]);
    const [filteredScout, setFilteredScout] = useState('');

    const dropDownRef = useRef<HTMLDivElement>(null);
    useClickOutside({ controlledElementRef: dropDownRef, handler: () => setIsDropDownShown(false) });

    const onStateFilterItemClick = (idx: number, event?: React.MouseEvent) => {
        event?.stopPropagation();
        if (filteredStreamerStates.includes(idx)) {
            setFilteredStreamerStates(prev => prev.filter(el => el !== idx));
        } else {
            setFilteredStreamerStates(prev => prev.concat(idx));
        }
    }

    const onTypeFilterItemClick = (idx: number, event?: React.MouseEvent) => {
        event?.stopPropagation();
        if (filteredStreamerTypes.includes(idx)) {
            setFilteredStreamerTypes(prev => prev.filter(el => el !== idx));
        } else {
            setFilteredStreamerTypes(prev => prev.concat(idx));
        }
    }

    const onDaTypeFilterItemClick = (idx: number, event?: React.MouseEvent) => {
        event?.stopPropagation();
        if (filteredDaTypes.includes(idx)) {
            setFilteredDaTypes(prev => prev.filter(el => el !== idx));
        } else {
            setFilteredDaTypes(prev => prev.concat(idx));
        }
    }

    const onScoutFilterItemClick = (idx: number, event?: React.MouseEvent) => {
        event?.stopPropagation();
        setFilteredScout(filteredScout === scouts[idx] ? '' : scouts[idx]);
    }

    const statesItems = statesValues.map((caption, idx) => (
        <li
            key={idx}
            className={s.complexFilterDropDownItem}
            onClick={(event) => onStateFilterItemClick(idx, event)}
        >
            <div className={cn(
                s.complexFilterDropDownItemCheck,
                { [s.complexFilterDropDownItemCheckActive]: filteredStreamerStates.includes(idx)}
            )}/>
            <div className={s.complexFilterDropDownItemText}>{caption}</div>
        </li>
    ))

    const typesItems = typesValues.map((caption, idx) => (
        <li
            key={idx}
            className={s.complexFilterDropDownItem}
            onClick={(event) => onTypeFilterItemClick(idx, event)}
        >
            <div className={cn(
                s.complexFilterDropDownItemCheck,
                { [s.complexFilterDropDownItemCheckActive]: filteredStreamerTypes.includes(idx)}
            )}/>
            <div className={s.complexFilterDropDownItemText}>{caption}</div>
        </li>
    ))

    const daTypesItems = daTypesValues.map((caption, idx) => (
        <li
            key={idx}
            className={s.complexFilterDropDownItem}
            onClick={(event) => onDaTypeFilterItemClick(idx, event)}
        >
            <div className={cn(
                s.complexFilterDropDownItemCheck,
                { [s.complexFilterDropDownItemCheckActive]: filteredDaTypes.includes(idx)}
            )}/>
            <div className={s.complexFilterDropDownItemText}>{caption}</div>
        </li>
    ))

    const scoutsItems = scouts.map((caption, idx) => (
        <li
            key={idx}
            className={s.complexFilterDropDownItem}
            onClick={(event) => onScoutFilterItemClick(idx, event)}
        >
            <div className={cn(
                s.complexFilterDropDownItemCheck,
                s.complexFilterDropDownItemCheckRadio,
                { [s.complexFilterDropDownItemCheckActive]: filteredScout === scouts[idx] }
            )}/>
            <div className={s.complexFilterDropDownItemText}>{caption}</div>
        </li>
    ))

    const buildFiltersText = () => {
        const strings = [];
        if (filteredStreamerStates.length) {
            const states = filteredStreamerStates.map(el => statesValues[el]).join(', ');
            strings.push(<span><span className={s.complexFilterTextRowTopic}>States: </span>{states}</span>);
        }
        if (filteredStreamerTypes.length) {
            const types = filteredStreamerTypes.map(el => typesValues[el]).join(', ')
            strings.push(<span><span className={s.complexFilterTextRowTopic}>Types: </span>{types}</span>);
        }
        if (filteredDaTypes.length) {
            const types = filteredDaTypes.map(el => daTypesValues[el]).join(', ')
            strings.push(<span><span className={s.complexFilterTextRowTopic}>DA Types: </span>{types}</span>);
        }
        if (filteredScout) {
            strings.push(<span><span className={s.complexFilterTextRowTopic}>Processed by: </span>{filteredScout}</span>);
        }

        if (!strings.length) {
            return 'Фильтры: Нет'
        } else {
            return (
                <Row noPadding className={s.complexFilterText}>
                    { strings.map(str => (
                        <Row noPadding className={cn(s.complexFilterTextRow, s[`complexFilterTextRowSize${strings.length}`])}>
                            {str}
                        </Row>
                    )) }
                </Row>
            )
        }
    }

    useEffect(() => {
        onFilterChange({
            scout: filteredScout,
            states: filteredStreamerStates,
            daTypes: filteredDaTypes,
            types: filteredStreamerTypes,
        })
    }, [filteredStreamerStates, filteredStreamerTypes, filteredScout, filteredDaTypes]);

    return (
        <Column noPadding className={s.complexFilter}>
            <div className={s.complexFilterText} onClick={() => setIsDropDownShown(prev => !prev)} ref={dropDownRef}>
                {buildFiltersText()}
            </div>
            {isDropDownShown &&
                <Row noPadding className={s.complexFilterDropDown} alignment={RowItemsAlignment.TOP}>
                    <Column noPadding>
                        <div className={s.complexFilterDropDownListTitle}>
                            Status
                        </div>
                        <ul className={s.complexFilterDropDownList}>
                            {statesItems}
                        </ul>
                    </Column>
                    <Column noPadding>
                        <div className={s.complexFilterDropDownListTitle}>
                            Type
                        </div>
                        <ul className={s.complexFilterDropDownList}>
                            {typesItems}
                        </ul>
                    </Column>
                    <Column noPadding>
                        <div className={s.complexFilterDropDownListTitle}>
                            DaType
                        </div>
                        <ul className={s.complexFilterDropDownList}>
                            {daTypesItems}
                        </ul>
                    </Column>
                    <Column noPadding>
                        <div className={s.complexFilterDropDownListTitle}>
                            Scout
                        </div>
                        <ul className={s.complexFilterDropDownList}>
                            {scoutsItems}
                        </ul>
                    </Column>
                </Row>
            }
        </Column>
    )
}
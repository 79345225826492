import React from 'react';
import cn from 'classnames';

import s from './Column.scss';

export enum ColumnItemsJustification {
    TOP = 'justifyTop',
    BOTTOM = 'justifyBottom',
    CENTER = 'justifyCenter',
    STRETCH = 'justifyStretch',
}

export enum ColumnItemsAlignment {
    BEGIN = 'alignBegin',
    END = 'alignEnd',
    CENTER = 'alignCenter',
    STRETCH = 'alignStretch',
    SPACE_BETWEEN = 'alignSpaceBetween',
}

interface ColumnProps {
    alignment?: ColumnItemsAlignment;
    className?: string;
    grow?: boolean;
    justification?: ColumnItemsJustification;
    noPadding?: boolean;
}

export const Column = (props: React.PropsWithChildren<ColumnProps>) => {
    const {
        alignment = ColumnItemsAlignment.STRETCH,
        className,
        children,
        grow = false,
        justification = ColumnItemsJustification.STRETCH,
        noPadding = false,
    } = props;
    return (
        <div className={cn({
            [s.column]: true,
            [s[alignment]]: true,
            [s[justification]]: true,
            [s.grow]: grow,
            [s.noPadding]: noPadding,
        }, className)}>
            { children }
        </div>
    )
}
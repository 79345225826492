import React from 'react';
import cn from 'classnames';
import { noop } from '@common';
import s from './Paginator.scss';

interface PaginatorProps {
    activePage?: number;
    pagesCount?: number;
    onPageSelect?: (page: number) => void;
}

export const Paginator = (props: PaginatorProps) => {
    const { activePage = 1, pagesCount = 10, onPageSelect = noop } = props;

    const elements = Array.from({ length: pagesCount }).map((_, idx) => (
        <div
            className={cn(s.page, { [s.pageActive]: activePage === idx + 1})}
            key={idx + 1}
            onClick={() => onPageSelect(idx + 1)}
        >
            {idx + 1}
        </div>
    ))

    return (
        <div className={s.paginator}>
            {elements}
        </div>
    )
}
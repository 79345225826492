import { Column, Row, ScrollableArea } from '@components';
import { useLogger, useModals } from '@hooks';
import React from 'react';

import { useRegisteredStreamers } from '@hooks/workbench/Streamers';
import s from './StreamersHold.scss';
import { Paginator, SearchBar } from '@views';
import { IRegisteredStreamer } from '@interfaces';
import { TrackingHistory } from '../../modals/TrackingHistory';

export const StreamersHold = () => {
    const logger = useLogger({ target: StreamersHold.name });

    const { showModal, closeModal } = useModals();

    const {
        selectedStreamer,
        streamers,
        page,
        pagesCount,
        deselectStreamer,
        selectStreamer,
        selectPage,
        setQuery,
    } = useRegisteredStreamers();

    const showTrackingHistory = (streamer: IRegisteredStreamer) => {
        showModal({
            content: <TrackingHistory streamer={streamer} />,
            options: {
                bodyClassName: s.trackingHistoryModalBody,
                preventCloseOnShadow: true,
            }
        })
    }

    const tableRows = streamers?.map((streamer, idx) => (
        <tr className={s.tableRow} key={streamer.id}>
            <td className={s.tableRowCell}>{(page - 1) * 100  + idx + 1}</td>
            <td className={s.tableRowCell}>{streamer.name}</td>
            <td className={s.tableRowCell}></td>
            <td className={s.tableRowCell}></td>
            <td className={s.tableRowCell}></td>
            <td className={s.tableRowCell}></td>
            <td className={s.tableRowCell}></td>
            <td className={s.tableRowCell}></td>
            <td className={s.tableRowCell}>
                <a href='#' className={s.link} onClick={() => showTrackingHistory(streamer)}>
                    {`[Contact]`}
                </a>
            </td>
        </tr>
    ));

    return (
        <>
            <Row noPadding>
                <Column grow noPadding>
                    <Row className={s.topBar} noPadding>
                        <SearchBar onChange={(v) => setQuery(v?.toString() ?? '')}/>
                        {/* <StatusFilter filterValues={StreamerStateCaptions} onFilterChange={setStatusFilter} /> */}
                        {/* <CommonStatistics {...workbench} /> */}
                        <Column grow />
                        <Paginator
                            pagesCount={pagesCount}
                            activePage={page}
                            onPageSelect={selectPage}
                        />
                    </Row>
                </Column>
            </Row>
            <ScrollableArea>
                <table className={s.table}>
                    <thead>
                        <tr className={s.tableHeader}>
                            <td className={s.tableHeaderCell}>{'Top'}</td>
                            <td className={s.tableHeaderCell}>{'Streamer'}</td>
                            <td className={s.tableHeaderCell}>{'MA'}</td>
                            <td className={s.tableHeaderCell}>{'Last Stream'}</td>
                            <td className={s.tableHeaderCell}>{'Last Sticker'}</td>
                            <td className={s.tableHeaderCell}>{'Last Contact'}</td>
                            <td className={s.tableHeaderCell}>{'Comment'}</td>
                            <td className={s.tableHeaderCell}>{'Scout'}</td>
                            <td className={s.tableHeaderCell}>{'Contact'}</td>
                        </tr>
                    </thead>
                    <tbody>
                        {tableRows}
                    </tbody>
                </table>
            </ScrollableArea>
        </>
    )
}
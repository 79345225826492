import { isMobile as CheckMobile } from '@common';
// import { Dimensions } from '@interfaces';
import { useState } from 'react';
import { useLogger } from './Logger';

export const useDeviceDetection = () => {
    const logger = useLogger({ target: 'DeviceDetection' });
    const { isMobile, isAppleMobileDevice } = CheckMobile();

    // const [ viewportDimensions, setViewportDimensions ] = useState<Dimensions>({ width: 0, height: 0 });
    const [ isMobileLayoutRecommended, setIsMobileLayoutRecommended ] = useState(false);

    // const updateViewportDimensions = () => {
    //     if (window) {
    //         setViewportDimensions({
    //             width: window.innerWidth,
    //             height: window.innerHeight,
    //         });
    //     } else {
    //         setViewportDimensions({
    //             width: 0,
    //             height: 0,
    //         });
    //     }
    // }

    // useLayoutEffect(() => {
    //     // TODO: Use config.fixedWidth instead of constant below (1040)
    //     if (viewportDimensions.width && viewportDimensions.height && viewportDimensions.width <= 1040) {
    //         setIsMobileLayoutRecommended(true);
    //     }
    // }, [viewportDimensions]);

    // useLayoutEffect(() => {
    //     updateViewportDimensions();
    // }, [])

    return {
        isMobile,
        isAppleMobileDevice,
        //viewportDimensions,
        isMobileLayoutRecommended
    };
};

import React, { useState } from 'react';

export type InputValueType = string | ReadonlyArray<string> | number | undefined;

interface InputParams {
    debounceTimeMs?: number;
    initialValue?: InputValueType;
    onAction?: () => void;
    onChange?: (value: InputValueType) => void;
}

let debounceTimer: NodeJS.Timeout;

export const useInput = (params: InputParams) => {
    const {
        debounceTimeMs,
        initialValue,
        onAction,
        onChange,
    } = params;

    const [inputValue, setInputValue] = useState<InputValueType>(initialValue ?? '');

    const changeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        setInputValue(value);
        if (debounceTimeMs) {
            clearTimeout(debounceTimer);
            debounceTimer = setTimeout(() => {
                onChange && onChange(value);
            }, debounceTimeMs);
        } else {
            onChange && onChange(value);
        }
    }

    const keyPressHandler = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key.toUpperCase() === 'ENTER') {
            onAction && onAction();
        }
    };

    return {
        value: inputValue,
        onChange: changeHandler,
        onKeyDown: keyPressHandler,
    }
}

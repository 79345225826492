import { ICommonStatistics, IScoutStatistics, IScoutsStatisticsPageLoadParams, IScoutsStatisticsPageLoadResult } from '@interfaces';
import { ICommonStatisticsRecord, LoadPageParams, PageOf } from '@typings';
import { cpApi } from './cp-base.api';

export const cpScoutsApi = cpApi.injectEndpoints({
    endpoints: build => ({
        getScoutsStatisticsPage: build.query<IScoutsStatisticsPageLoadResult, IScoutsStatisticsPageLoadParams>({
            query: (params: IScoutsStatisticsPageLoadParams) => ({
                url: 'scouts-statistics/page',
                method: 'POST',
                body: params,
            }),
            providesTags: ['ScoutsStatistics'],
        }),
        getDailyStatisticPage: build.query<PageOf<ICommonStatisticsRecord>, LoadPageParams>({
            query: (params: LoadPageParams) => ({
                url: 'scouts-statistics/daily/page',
                method: 'POST',
                body: params,
            }),
            providesTags: ['DailyStatistic'],
        }),
        getScoutStatistics: build.query<IScoutStatistics, void>({
            query: () => ({
                url: 'scouts-statistics',
                method: 'GET',
            }),
            providesTags: ['ScoutStatistics'],
        }),
        getCommonStatistics: build.query<ICommonStatistics, void>({
            query: () => ({
                url: 'scouts-statistics/common',
                method: 'GET',
            }),
            providesTags: ['CommonStatistics'],
        }),
    })
});

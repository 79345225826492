import { Column, Row, RowItemsAlignment } from '@components';
import { WorkbenchHookReturnType } from '@hooks';
import React from 'react';
import { Outlet, useOutletContext } from 'react-router-dom';
import s from './Admin.scss';
import { AdminMenu } from './components';

export const Admin = () => {
    const workbench = useOutletContext<WorkbenchHookReturnType>();

    return (
        <Column grow noPadding>
            <Row grow alignment={RowItemsAlignment.STRETCH}>
                <Column noPadding className={s.leftColumn}>
                    <AdminMenu />
                </Column>
                <Column grow>
                    <Outlet context={workbench}/>
                </Column>
            </Row>
        </Column>
    )
}
// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".MarketerCrm__leftColumn--cZvGb {\n  padding-top: 15px;\n  min-width: 200px;\n}", "",{"version":3,"sources":["webpack://./src/views/modes/MarketerCrm/MarketerCrm.scss"],"names":[],"mappings":"AAAA;EACI,iBAAA;EACA,gBAAA;AACJ","sourcesContent":[".leftColumn {\r\n    padding-top: 15px;\r\n    min-width: 200px;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"leftColumn": "MarketerCrm__leftColumn--cZvGb"
};
export default ___CSS_LOADER_EXPORT___;

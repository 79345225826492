import { ComponentColor, ScrollableArea } from '@components';
import { useCurrentUser, useLogger, useModals } from '@hooks';
import React, { useEffect, useRef } from 'react';

import { useBonuses, useScoutPayout } from '@hooks/workbench/Streamers';
import { IStreamerTwitchData } from '@interfaces';
import { FreeingConfirmation, StatusHistory } from '../../modals';
import s from './Bonuses.scss';
import { ScoutBonuses } from './components';

export const Bonuses = () => {
    const logger = useLogger({ target: Bonuses.name });

    const { showModal, closeModal } = useModals();

    const {
        selectedStreamer,
        streamers,
        deselectStreamer,
        payToScout,
        selectStreamer,
    } = useBonuses();

    const {
        currentUser,
    } = useCurrentUser();

    const selectedStreamerRef = useRef(selectedStreamer);

    const scoutNames = Array.from(new Set(streamers.map(streamer => streamer.scout?.username).values()));

    const scoutDataTables = scoutNames
        .map(name => ({ name, data: streamers.filter(streamer => streamer.scout?.username === name) }))
        .sort((s1, s2) => s1.name! > s2.name! ? 1 : s1.name === s2.name ? 0 : -1);

    const showPayConfirmation = (streamer: IStreamerTwitchData) => {
        selectStreamer(streamer);
        showModal({
            content: <FreeingConfirmation text={'Уверены, что хотите открепить скаута и выплатить бонусы?'}/>,
            options: {
                withHeader: true,
                caption: `Внимание ${currentUser?.username}`,
                bodyClassName: s.bindingConfirmation,
                buttons: [
                    { caption: 'Да', onClick: () => okPayConfirmation() },
                    { caption: 'Нет', onClick: () => cancelPayConfirmation(), color: ComponentColor.WHITE }
                ]
            }
        })
    }

    const okPayConfirmation = () => {
        if (currentUser?.id && selectedStreamerRef.current?.id) {
            payToScout({
                streamerId: selectedStreamerRef.current.id,
                scoutId: currentUser?.id,
            });
        }
        closeModal();
    }

    const cancelPayConfirmation = () => {
        deselectStreamer();
        closeModal();
    }

    const showHistory = (streamer: IStreamerTwitchData) => {
        selectStreamer(streamer);
        showModal({
            content: <StatusHistory streamer={streamer} statusHistory={streamer.statusHistory}/>,
            options: {
                withHeader: true,
                caption: `История изменения статуса ${streamer.name.toUpperCase()}`,
                bodyClassName: s.bindingConfirmation,
                buttons: [
                    { caption: 'Закрыть', onClick: closeModal, color: ComponentColor.ACCENT }
                ]
            }
        })
    }

    useEffect(() => {
        selectedStreamerRef.current = selectedStreamer;
    }, [selectedStreamer]);

    return (
        <>
            <ScrollableArea>
                {
                    scoutDataTables.map((data, idx) => (
                        <ScoutBonuses
                            key={idx}
                            scoutName={data.name!}
                            data={data.data}
                            showPayButton={currentUser?.flags?.isAdmin}
                            onPayClick={showPayConfirmation}
                            onHistoryClick={showHistory}
                        />
                    ))
                }
            </ScrollableArea>
        </>
    )
}

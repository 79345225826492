import { IScoutMetrics } from './IScoutMetrics';

export interface IScoutStatistics {
    id: string;
    username: string;
    total: IScoutMetrics;
    lastMonth: IScoutMetrics;
    thisMonth: IScoutMetrics;
    lastWeek: IScoutMetrics;
    thisWeek: IScoutMetrics;
}

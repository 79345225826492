import { Column, ComponentSize, Menu, MenuItemIconPosition, MenuItemProps, MenuOrientation } from '@components';
import React from 'react';
import { useMatch, useNavigate } from 'react-router-dom';

export const MarketerCrmMenu = () => {
    const navigate = useNavigate();

    const menu: Array<MenuItemProps> = [
        {
            caption: 'Удержание стримеров',
            isActive: !!useMatch('/marketer/streamers-hold'),
            iconPosition: MenuItemIconPosition.LEFT,
            iconSize: ComponentSize.SMALL,
            onClick: () => navigate('/marketer/streamers-hold')
        },
    ];

    return (
        <Column noPadding grow>
            <Column noPadding grow>
                <Menu elements={menu} orientation={MenuOrientation.VERTICAL}/>
            </Column>
        </Column>
    )
}
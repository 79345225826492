import { Button, Column, ComponentColor, IconSelector, Input, RoundedAvatar, Row, RowItemsAlignment, ScrollableArea, TextArea } from '@components';
import React, { useEffect, useState } from 'react';

import { NotificationType } from '@contexts';
import { StreamerHistoryCommentTypeIcons, TrackingHistoryCommentTypeText, useCurrentUser, useLogger, useModals, useNotifications, useTrackingHistory } from '@hooks';
import { IconCrossLine } from '@images/svg';
import { IRegisteredStreamer } from '@interfaces';
import { StreamingPlatform } from '@typings';
import cn from 'classnames';
import s from './TrackingHistory.scss';
import { HistoryRecord, RadioItem } from './components';

interface TrackingHistoryProps {
    streamer: IRegisteredStreamer;
}

const Icons = Object.values(StreamerHistoryCommentTypeIcons).slice(0, 5);
Icons.push(Object.values(StreamerHistoryCommentTypeIcons)[11]);

export const TrackingHistory = (props: TrackingHistoryProps) => {
    const {
        streamer,
    } = props;

    const {
        currentUser,
    } = useCurrentUser();

    const {
        history,
        selectedCommentType,
        changeSelectedCommentType,
        createHistoryRecord,
        loadHistory,
        updateRegisteredStreamer,
    } = useTrackingHistory();

    const logger = useLogger({ target: TrackingHistory.name });
    const { closeModal } = useModals();
    const { notify } = useNotifications();

    const [isTwitchMain, setIsTwitchMain] = useState(streamer.mainStreamingPlatform === StreamingPlatform.TWITCH);
    const [isYouTubeMain, setIsYouTubeMain] = useState(streamer.mainStreamingPlatform === StreamingPlatform.YOUTUBE);
    const [mainPlatform, setMainPlatform] = useState<StreamingPlatform>(StreamingPlatform.TWITCH);
    const [twitchLink, setTwitchLink] = useState<string>(streamer.twitchLink ?? '');
    const [twitchLinkValid, setTwitchLinkValid] = useState(false);
    const [youtubeLink, setYoutubeLink] = useState<string>(streamer.youtubeLink ?? '');
    const [youtubeLinkValid, setYoutubeLinkValid] = useState(false);
    const [altTelegramLink, setAltTelegramLink] = useState<string>(streamer.altTelegramLink ?? '');
    const [altTelegramLinkValid, setAltTelegramLinkValid] = useState(false);

    const [comment, setComment] = useState<string>();

    const onSelectionChange = (selection: number) => {
        changeSelectedCommentType(selection);
    }

    const onTwitchIsMainChange = (isMain: boolean) => {
        if (isMain === isTwitchMain) return;
        setMainPlatform(StreamingPlatform.TWITCH);
        setIsTwitchMain(isMain);
        setIsYouTubeMain(!isMain);
    }

    const onYouTubeIsMainChange = (isMain: boolean) => {
        if (isMain === isYouTubeMain) return;
        setMainPlatform(StreamingPlatform.YOUTUBE);
        setIsYouTubeMain(isMain);
        setIsTwitchMain(!isMain);
    }

    const onSaveClick = async () => {
        if (currentUser && streamer.id) {
            const result = await updateRegisteredStreamer({
                streamerId: streamer.id,
                channel: {
                    manager: { id: currentUser.id, username: currentUser.username },
                    mainStreamingPlatform: mainPlatform,
                    twitchLink: twitchLink.toString(),
                    youtubeLink: youtubeLink.toString(),
                    altTelegramLink: altTelegramLink.toString(),
                }
            }).unwrap();

            if (result) {
                notify('Изменения успешно сохранены', NotificationType.SUCCESS);
            }
        }
    }

    const onSendClick = () => {
        if (comment && currentUser && streamer.id) {
            createHistoryRecord({
                comment,
                type: selectedCommentType,
                user: currentUser,
                timestamp: Date.now(),
                streamerId: streamer.id,
            });
        }
    }

    const onCloseClick = () => {
        closeModal();
    }

    const historyRecords = history
        .map((record, idx) => (
            <HistoryRecord historyData={record} key={idx} />
        ));

    useEffect(() => {
        if (streamer.id) {
            loadHistory(streamer.id);
        }
    }, []);

    useEffect(() => {
        const twitchRegExp = /http(s)?:\/\/(www\.)?twitch\.(tv|com)\/[a-zA-Z0-9_]+/i;
        setTwitchLinkValid(!twitchLink || twitchRegExp.test(twitchLink.toString()));
    }, [twitchLink]);

    useEffect(() => {
        const youtubeRegExp = /http(s)?:\/\/(www\.)?youtube\.com\/[@a-zA-Z0-9_\/]+/i;
        setYoutubeLinkValid(!youtubeLink || youtubeRegExp.test(youtubeLink.toString()));
    }, [youtubeLink]);

    useEffect(() => {
        const telegramRegExp = /http(s)?:\/\/t\.me\/[a-zA-Z0-9_]+/i;
        setAltTelegramLinkValid(!altTelegramLink || telegramRegExp.test(altTelegramLink.toString()));
    }, [altTelegramLink]);

    return (
        <Row
            noPadding
            alignment={RowItemsAlignment.STRETCH}
            className={s.trackingHistory}
        >
            <Column grow className={s.comments}>
                <Row className={s.commentsStreamerName}>
                    {streamer?.channelLink}
                </Row>
                <ScrollableArea>
                    {historyRecords}
                </ScrollableArea>
            </Column>
            <Column grow className={s.form}>
                <Row>
                    <Column noPadding>
                        <RoundedAvatar source={streamer.avatar} className={s.avatar}/>
                    </Column>
                    <Column noPadding grow className={s.mainInfo}>
                        <Row noPadding className={s.mainInfoName}>{streamer.name}</Row>
                        <Row noPadding className={s.mainInfoStatus}>Статус: {`☺️ Active`}</Row>
                    </Column>
                </Row>
                <Row className={s.social}>
                    <Row noPadding grow className={s.socialName}>MA:
                        <a
                            href={`https://memealerts.com/${streamer.channelLink}`}
                            target='_blank'
                            className={s.socialLink}
                            title={streamer.channelLink}
                        >
                            {streamer.channelLink}
                        </a>
                    </Row>
                    <Row noPadding grow className={s.socialName}>DA:
                        <a
                            href={`https://www.donationalerts.com/r/${streamer.daLink}`}
                            target='_blank'
                            className={s.socialLink}
                            title={streamer.daLink}
                        >
                            {streamer.daLink}
                        </a>
                    </Row>
                    <Row noPadding grow className={s.socialName}>TG:
                        <a
                            href={streamer.telegramLink}
                            target='_blank'
                            className={s.socialLink}
                            title={streamer.telegramLink}
                        >
                            {streamer.telegramLink?.replace('https://t.me/', '')}
                        </a>
                    </Row>
                </Row>
                <Row className={s.extraInfo} alignment={RowItemsAlignment.TOP}>
                    <Column noPadding grow className={s.extraInfoColumn}>
                        <Row noPadding>Registered: {new Date(streamer.createdAt).toLocaleDateString()}</Row>
                        <Row noPadding>Link: {}</Row>
                        <Row noPadding>Scout: {streamer.manager ? streamer.manager.username : 'No'}</Row>
                    </Column>
                    <Column noPadding grow className={s.extraInfoColumn}>
                        <Row noPadding>Last stream: {}</Row>
                        <Row noPadding>Last sticker: {}</Row>
                    </Column>
                </Row>
                <Row>
                    <Column noPadding grow>
                        <Row noPadding grow>
                            <Input
                                caption={<Row className={cn(s.inputCaption, { [s.inputCaptionError]: !altTelegramLinkValid})} noPadding>Альтернативный TG</Row>}
                                className={s.linkInput}
                                initialValue={streamer.altTelegramLink}
                                onChange={(val) => setAltTelegramLink(val?.toString() ?? '')}
                            />
                        </Row>
                        <Row noPadding grow>
                            <Input
                                caption={
                                    <Row className={cn(s.inputCaption, { [s.inputCaptionError]: !twitchLinkValid})} noPadding>
                                        Twitch <RadioItem caption={'Основной'} selected={isTwitchMain} onChange={(v) => onTwitchIsMainChange(v)}/>
                                    </Row>}
                                className={s.linkInput}
                                initialValue={streamer.twitchLink}
                                onChange={(val) => setTwitchLink(val?.toString() ?? '')}
                            />
                        </Row>
                        <Row noPadding grow>
                            <Input
                                caption={
                                    <Row className={cn(s.inputCaption, { [s.inputCaptionError]: !youtubeLinkValid})} noPadding>
                                        YouTube <RadioItem caption={'Основной'} selected={isYouTubeMain} onChange={(v) => onYouTubeIsMainChange(v)}/>
                                    </Row>}
                                className={s.linkInput}
                                initialValue={streamer.youtubeLink}
                                onChange={(val) => setYoutubeLink(val?.toString() ?? '')}
                            />
                        </Row>
                    </Column>
                </Row>
                <Row>
                    <Button
                        color={ComponentColor.ACCENT}
                        caption={'Сохранить'}
                        disabled={!currentUser || !streamer || !altTelegramLinkValid || !twitchLinkValid || !youtubeLinkValid}
                        onClick={onSaveClick}
                    />
                </Row>
                <Row>
                    <TextArea maxRows={4} onChange={(val) => setComment(val?.toString())} />
                </Row>
                <Row>
                    <IconSelector
                        defaultSelection={-1}
                        icons={Icons}
                        onSelectionChange={onSelectionChange}
                    />
                    {TrackingHistoryCommentTypeText[selectedCommentType]}
                </Row>
                <Row>
                    <Button
                        color={ComponentColor.ACCENT}
                        caption={'Отправить'}
                        disabled={!currentUser || !comment || selectedCommentType < 0}
                        onClick={onSendClick}
                    />
                </Row>
            </Column>
            <div
                className={s.trackingHistoryClose}
                onClick={onCloseClick}
            >
                <IconCrossLine />
            </div>
        </Row>
    )
}
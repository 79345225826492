import { ScrollableArea } from '@components';
import { useLogger } from '@hooks';
import React from 'react';

import { useScoutsStatistics } from '@hooks/workbench/Streamers';
import s from './ScoutsStatistic.scss';

export const ScoutsStatistic = () => {
    const logger = useLogger({ target: ScoutsStatistic.name });

    const {
        statistics,
    } = useScoutsStatistics();

    const tableRows = statistics.map((stat, idx) => (
        <tr className={s.tableRow} key={stat.id}>
            <td className={s.tableRowCell}>{idx + 1}</td>
            <td className={s.tableRowCell}>{stat.username}</td>
            <td className={s.tableRowCell}>{stat.total.active}</td>
            <td className={s.tableRowCell}>{stat.total.inProgress}</td>
            <td className={s.tableRowCell}>{stat.lastMonth.active}</td>
            <td className={s.tableRowCell}>{stat.lastMonth.inProgress}</td>
            <td className={s.tableRowCell}>{stat.thisMonth.active}</td>
            <td className={s.tableRowCell}>{stat.thisMonth.inProgress}</td>
            <td className={s.tableRowCell}>{stat.lastWeek.active}</td>
            <td className={s.tableRowCell}>{stat.lastWeek.inProgress}</td>
            <td className={s.tableRowCell}>{stat.thisWeek.active}</td>
            <td className={s.tableRowCell}>{stat.thisWeek.inProgress}</td>
        </tr>
    ));

    return (
        <ScrollableArea>
            <table className={s.table}>
                <thead>
                    <tr className={s.tableHeader1}>
                        <td className={s.tableHeader1Cell}>{''}</td>
                        <td className={s.tableHeader1Cell}>{''}</td>
                        <td className={s.tableHeader1Cell} colSpan={2}>{'Всего'}</td>
                        <td className={s.tableHeader1Cell} colSpan={2}>{'За прошлый месяц'}</td>
                        <td className={s.tableHeader1Cell} colSpan={2}>{'В этом месяце'}</td>
                        <td className={s.tableHeader1Cell} colSpan={2}>{'За прошлую неделю'}</td>
                        <td className={s.tableHeader1Cell} colSpan={2}>{'На этой неделе'}</td>
                    </tr>
                    <tr className={s.tableHeader2}>
                        <td className={s.tableHeader2Cell}>{'#'}</td>
                        <td className={s.tableHeader2Cell}>{'Скаут'}</td>
                        <td className={s.tableHeader2Cell}>{'Активных'}</td>
                        <td className={s.tableHeader2Cell}>{'В процессе'}</td>
                        <td className={s.tableHeader2Cell}>{'Активных'}</td>
                        <td className={s.tableHeader2Cell}>{'В процессе'}</td>
                        <td className={s.tableHeader2Cell}>{'Активных'}</td>
                        <td className={s.tableHeader2Cell}>{'В процессе'}</td>
                        <td className={s.tableHeader2Cell}>{'Активных'}</td>
                        <td className={s.tableHeader2Cell}>{'В процесе'}</td>
                        <td className={s.tableHeader2Cell}>{'Активных'}</td>
                        <td className={s.tableHeader2Cell}>{'В процессе'}</td>
                    </tr>
                </thead>
                <tbody>
                    {tableRows}
                </tbody>
            </table>
        </ScrollableArea>
    )
}

import React from 'react'
import { useRoutes } from 'react-router-dom';

import s from './App.scss';
import { AuthProvider, ModalProvider, ModalShadow, NotificationArea, NotificationProvider } from '@contexts';
import { Routing } from './routing/Routing';

export const App = () => {
    const routes = useRoutes(Routing);

    return (
        <AuthProvider>
            <ModalProvider>
                <NotificationProvider>
                    <div className={s.root}>
                        { routes }
                    </div>
                    <ModalShadow />
                    <NotificationArea />
                </NotificationProvider>
            </ModalProvider>
        </AuthProvider>
    );
};

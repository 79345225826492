import { ComponentSize, Menu, MenuItemIconPosition, MenuItemProps, MenuOrientation } from '@components';
import { useCurrentUser } from '@hooks';
import { IconCogLine, IconStarLine, IconUserLine } from '@images/svg';
import React from 'react';
import { useMatch, useNavigate } from 'react-router-dom';

export const TopMenu = () => {
    const navigate = useNavigate();
    const { currentUser } = useCurrentUser();

    const flags = currentUser?.flags ?? {};
    const roles = Object.keys(flags).filter(key => (flags as any)[key] === true);
    const onlyOneRole = roles ? roles.length === 1 : true;

    const isNotOnMarketerPage = !useMatch('/marketer/*');
    const isNotOnScoutPage = !useMatch('/scout/*');

    if (onlyOneRole) {
        if (roles.includes('isMarketer') && isNotOnMarketerPage) {
            navigate('/marketer/streamers-hold');
        }
        if (roles.includes('isScout') && isNotOnScoutPage) {
            navigate('/scout/streamers');
        }
    }

    const menu: Array<MenuItemProps> = [
        {
            caption: 'Админ',
            hidden: !currentUser?.flags?.isAdmin,
            isActive: !!useMatch('/admin/*'),
            icon: <IconCogLine />,
            iconPosition: MenuItemIconPosition.TOP,
            iconSize: ComponentSize.MEDIUM,
            onClick: () => navigate('/admin')
        },
        {
            caption: 'Маркетолог',
            isActive: !!useMatch('/marketer/*'),
            hidden: !(currentUser?.flags?.isMarketer || currentUser?.flags?.isAdmin) || onlyOneRole,
            icon: <IconStarLine />,
            iconPosition: MenuItemIconPosition.TOP,
            iconSize: ComponentSize.MEDIUM,
            onClick: () => navigate('/marketer')
        },
        {
            caption: 'Скаут',
            isActive: !!useMatch('/scout/*'),
            hidden: !(currentUser?.flags?.isScout || currentUser?.flags?.isAdmin) || onlyOneRole,
            icon: <IconUserLine />,
            iconPosition: MenuItemIconPosition.TOP,
            iconSize: ComponentSize.MEDIUM,
            onClick: () => navigate('/scout/streamers')
        },
    ];

    return (
        <Menu elements={menu} orientation={MenuOrientation.HORIZONTAL}/>
    )
}
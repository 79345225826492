import { Column, ComponentSize, MenuItem, MenuItemIconPosition, Row, RowItemsJustification } from '@components';
import { WorkbenchHookReturnType, useCurrentUser } from '@hooks';
import React from 'react';

import s from './Header.scss';
import { IconLogoutLine } from '@images/svg';
import { TopMenu } from './components';
import { useStreamers } from '@hooks/workbench/Streamers';

interface HeaderProps extends WorkbenchHookReturnType {}

export const Header = (props: HeaderProps) => {
    const {
        logout,
    } = props;

    const {
        currentUser,
    } = useCurrentUser();

    const {
        lastDbUpdate,
    } = useStreamers();

    return (
        <Row className={s.header} noPadding justification={RowItemsJustification.SPACE_BETWEEN}>
            <Column className={s.headerBrand}>
                {'MemeAlerts'}
            </Column>
            <Column noPadding>
                <TopMenu />
            </Column>
            <Column grow/>
            <Column className={s.lastDbUpdate}>
                <Row className={s.lastDbUpdateCaption} justification={RowItemsJustification.CENTER} noPadding>
                    {'Последнее обновление базы'}
                </Row>
                <Row className={s.lastDbUpdateDateTime} justification={RowItemsJustification.CENTER} noPadding>
                    {lastDbUpdate}
                </Row>
            </Column>
            <Column className={s.loggedUser}>
                <Row className={s.loggedUserAs} justification={RowItemsJustification.CENTER} noPadding>
                    {'Вы вошли как'}
                </Row>
                <Row className={s.loggedUserName} justification={RowItemsJustification.CENTER} noPadding>
                    {currentUser?.username ?? 'Неизвестный'}
                </Row>
            </Column>
            <Column noPadding className={s.headerRightPanel}>
                <MenuItem
                    caption={'Exit'}
                    icon={<IconLogoutLine/>}
                    iconPosition={MenuItemIconPosition.TOP}
                    iconSize={ComponentSize.MEDIUM}
                    onClick={logout}
                />
            </Column>
        </Row>
    );
}
import { Column, ComponentSize, Menu, MenuItemIconPosition, MenuItemProps, MenuOrientation } from '@components';
import React from 'react';
import { useMatch, useNavigate } from 'react-router-dom';
import { PersonalStatistics } from '../PersonalStatistics';
import { WorkbenchHookReturnType } from '@hooks';

export const ScoutCrmMenu = () => {
    const navigate = useNavigate();

    const menu: Array<MenuItemProps> = [
        {
            caption: 'Стримеры',
            isActive: !!useMatch('/scout/streamers'),
            iconPosition: MenuItemIconPosition.LEFT,
            iconSize: ComponentSize.SMALL,
            onClick: () => navigate('/scout/streamers')
        },
        {
            caption: 'В работе',
            isActive: !!useMatch('/scout/in-progress'),
            iconPosition: MenuItemIconPosition.LEFT,
            iconSize: ComponentSize.SMALL,
            onClick: () => navigate('/scout/in-progress')
        },
        {
            caption: "История",
            isActive: !!useMatch('/scout/history'),
            iconPosition: MenuItemIconPosition.LEFT,
            iconSize: ComponentSize.SMALL,
            onClick: () => navigate('/scout/history')
        },
        {
            caption: "Статистика",
            isActive: !!useMatch('/scout/statistics'),
            iconPosition: MenuItemIconPosition.LEFT,
            iconSize: ComponentSize.SMALL,
            onClick: () => navigate('/scout/statistics')
        },
    ];

    return (
        <Column noPadding grow>
            <Column noPadding grow>
                <Menu elements={menu} orientation={MenuOrientation.VERTICAL}/>
            </Column>
            <PersonalStatistics/>
        </Column>
    )
}
import { IUser } from '@interfaces';
import { cpApi } from './cp-base.api';

export const cpUserApi = cpApi.injectEndpoints({
    endpoints: build => ({
        getCurrentUser: build.query<IUser, void>({
            query: () => ({
                url: 'user',
                method: 'GET',
            }),
        }),
        loadScoutsList: build.query<Array<IUser>, void>({
            query: () => ({
                url: 'user/scouts',
                method: 'GET',
            }),
        }),
    })
});

export interface ICommonStatistics {
    total: number;
    unregistered: number;
    nonactive: number;
    active: number;
    lost: number;
    big: number;
    bigActive: number;
    bigLost: number;
    medium: number;
    mediumActive: number;
    mediumLost: number;
    small: number;
    smallActive: number;
    smallLost: number;
    tiny: number;
    tinyActive: number;
    tinyLost: number;
}

export interface ICommonStatisticsRecord extends ICommonStatistics {
    timestamp: number;
}

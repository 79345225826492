import { useLogger } from '@hooks';
import { useApi } from '@hooks/webapi';
import { TrackingHistoryCommentType, ITrackingHistoryRecord } from '@typings';
import { useEffect, useState } from 'react';

export const TrackingHistoryCommentTypeIcons = {
    [TrackingHistoryCommentType.NO_CONTACT]: '☁️',
    [TrackingHistoryCommentType.OK]: '✅',
    [TrackingHistoryCommentType.WAIT]: '⏰',
    [TrackingHistoryCommentType.REFUSE]: '💩',
    [TrackingHistoryCommentType.ATTENTION]: '⚠️',
}

export const TrackingHistoryCommentTypeText = [
    'Нет контакта',
    'Стример согласился',
    'Стример думает',
    'Стример отказался',
    'Стример требует внимания',
];

export const useTrackingHistory = () => {
    const {
        trackingHistory: {
            useLazyGetTrackingHistoryQuery,
            useCreateTrackingHistoryRecordMutation,

        },
        registeredStreamers: {
            useUpdateRegisteredStreamerChannelMutation,
        }
    } = useApi();
    const logger = useLogger();

    const [ loadHistory, { data: loadedHistory } ] = useLazyGetTrackingHistoryQuery();
    const [ createHistoryRecord ] = useCreateTrackingHistoryRecordMutation();
    const [ updateRegisteredStreamer ] = useUpdateRegisteredStreamerChannelMutation()

    const [selectedCommentType, setSelectedCommentType] = useState(0);
    const [history, setHistory] = useState<Array<ITrackingHistoryRecord>>([]);

    const changeSelectedCommentType = (type: number) => {
        setSelectedCommentType(type);
    }

    useEffect(() => {
        if (loadedHistory?.length) {
            setHistory(loadedHistory);
        }
    }, [loadedHistory]);

    return {
        history,
        selectedCommentType,
        changeSelectedCommentType,
        createHistoryRecord,
        loadHistory,
        updateRegisteredStreamer,
    }
}
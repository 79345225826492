import { IPayoutSummary, IStreamerBinding, IStreamerTwitchData, IStreamersListPageLoadParams, IStreamersListPageLoadResult } from '@interfaces';
import { cpApi } from './cp-base.api';
import { LoadPageParams, LoadPageParamsWith, PageOf } from '@typings';

export const cpStreamersApi = cpApi.injectEndpoints({
    endpoints: build => ({
        getStreamersListPage: build.query<PageOf<IStreamerTwitchData>, LoadPageParamsWith<IStreamersListPageLoadParams>>({
            query: (params: LoadPageParamsWith<IStreamersListPageLoadParams>) => ({
                url: 'streamers/page',
                method: 'POST',
                body: params,
            }),
            providesTags: ["Streamers"],
        }),
        bindStreamerToScout: build.mutation<IStreamerTwitchData, IStreamerBinding>({
            query: (params: IStreamerBinding) => ({
                url: 'streamers/bind-scout',
                method: 'POST',
                body: params,
            }),
            invalidatesTags: ["Streamers"],
        }),
        unbindStreamerFromScout: build.mutation<IStreamerTwitchData, IStreamerBinding>({
            query: (params: IStreamerBinding) => ({
                url: 'streamers/bind-scout',
                method: 'DELETE',
                body: params,
            }),
            invalidatesTags: ["Streamers"],
        }),
        getPayoutSummary: build.query<IPayoutSummary, void>({
            query: () => ({
                url: 'streamers/payout/summary',
                method: 'GET',
            }),
            providesTags: ["PayoutSummary"],
        }),
    })
});

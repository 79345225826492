import React from 'react';
import cn from 'classnames';
import { CollapseButton } from './components';
import { useSideBar } from './hooks';
import s from './SideBar.scss';

interface SideBarProps {
    caption?: string;
    collapsible?: boolean;
    collapsedByDefault?: boolean;
}

export const SideBar = (props: React.PropsWithChildren<SideBarProps>) => {
    const {
        caption,
        children,
    } = props;

    const sideBar = useSideBar({...props});

    const {
        isCollapsed
    } = sideBar;

    return (
        <div
            className={cn(
                s.sidebar,
                { [s.sidebarCollapsed]: isCollapsed }
            )}
        >
            <div className={s.sidebarHeader}>
                    <div className={s.sidebarHeaderCaption}>
                        { !!caption ? caption : ': : : :' }
                    </div>

                <div className={s.sidebarHeaderButton}>
                    <CollapseButton {...sideBar} />
                </div>
            </div>

            <div
                className={cn(
                    s.sidebarContent,
                    { [s.sidebarContentCollapsed]: isCollapsed }
                )}
            >
                { children }
            </div>
        </div>
    );
};

import React from 'react'

import cn from 'classnames';

import s from './ScrollableArea.scss';

interface ScrollableAreaProps {
    className?: string;
    contentClassName?: string;
}

export const ScrollableArea = (props: React.PropsWithChildren<ScrollableAreaProps>) => {
    const { children, className, contentClassName } = props;

    return (
        <div className={cn(s.scrollableArea, className)}>
            <div className={cn(s.scrollableAreaContent, contentClassName)}>
                {children}
            </div>
        </div>
    );
};

import { AuthContext } from '@contexts';
import { useContext } from 'react';
import { useNavigate } from 'react-router-dom'
import { api } from 'src/store';

export const useLogin = () => {
    const navigate = useNavigate();
    const { updateToken, clearToken } = useContext(AuthContext);
    const [ postLogin, { data, isLoading } ] = api.auth.useLazyAuthQuery();

    const logout = () => {
        clearToken();
        navigate('/');
    }

    const login = async (username: string, password: string) => {
        if (username && password) {
            const loginResult = await postLogin({ username: username.toString(), password: password.toString() });
            if (loginResult.data?.token) {
                updateToken(loginResult.data?.token);
                navigate('/');
            }
        }
    }

    return {
        login,
        logout,
    }
}
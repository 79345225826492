import React from 'react';
import cn from 'classnames';

import s from './ToolBarButton.scss';
import { ComponentSize } from '@components/common';
import { ToolBarElement, ToolBarElementKind } from '../common';

enum ToolBarButtonType {
    BUTTON = 'button',
    SUBMIT = 'submit',
    RESET = 'reset',
}

export interface ToolBarButtonProps extends ToolBarElement {
    kind?: ToolBarElementKind.BUTTON;
    icon: JSX.Element;
    order?: number;
    size?: ComponentSize;
    type?: ToolBarButtonType;
    onClick?: () => void;
}

export const ToolBarButton = (props: ToolBarButtonProps) => {
    const {
        icon,
        order = 0,
        size = ComponentSize.LARGE,
        type = ToolBarButtonType.BUTTON,
        onClick,
    } = props;

    return (
        <button
            className={cn(
                { [s.toolbarButton]: true },
                { [s[size]]: true },
                { [s.clickable]: !!onClick },
            )}
            type={type}
        >
            <div className={cn(
                { [s.toolbarButtonIcon]: true },
            )}>
                { icon }
            </div>
        </button>
    )
}
// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".StatusBar__statusBar--Oi_OJ {\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n  justify-content: flex-start;\n  flex-grow: 1;\n  border-top: 1px solid #e8e8e8;\n}", "",{"version":3,"sources":["webpack://./src/components/status/StatusBar/StatusBar.scss"],"names":[],"mappings":"AAEA;EACI,aAAA;EACA,mBAAA;EACA,mBAAA;EACA,2BAAA;EACA,YAAA;EACA,6BAAA;AADJ","sourcesContent":["@import '/src/assets/styles/colors.scss';\r\n\r\n.statusBar {\r\n    display: flex;\r\n    flex-direction: row;\r\n    align-items: center;\r\n    justify-content: flex-start;\r\n    flex-grow: 1;\r\n    border-top: 1px solid $color-gray-100;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"statusBar": "StatusBar__statusBar--Oi_OJ"
};
export default ___CSS_LOADER_EXPORT___;

import { getCookie } from "@common";
import { AUTH_TOKEN_NAME } from "@contexts";
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import config from "config";

export const cpApi = createApi({
    reducerPath: "cp/api",
    tagTypes: [
        "AllTrackingHistory",
        "CommonStatistics",
        "StreamerHistory",
        "StreamersHistory",
        "CurrentUser",
        "DoneStreamers",
        "InProgressStreamers",
        "PayoutSummary",
        "RegisteredStreamers",
        "ScoutStatistics",
        "ScoutsStatistics",
        "Streamers",
        "TrackingHistory",
        "DailyStatistic",
    ],
    baseQuery: fetchBaseQuery({
        baseUrl: config.host.api,
        prepareHeaders: (headers, { getState }) => {
            const lsToken = localStorage.getItem(AUTH_TOKEN_NAME) ?? "";
            if (lsToken) {
                headers.set("authorization", `Bearer ${lsToken}`);
            }
            const csrfToken = getCookie("x-csrf-token");
            if (csrfToken) {
                headers.set("x-csrf-token", csrfToken);
            }
            return headers;
        },
    }),
    endpoints: () => ({}),
});

import { Column, Row } from '@components';
import { StreamerHistoryCommentTypeIcons } from '@hooks';
import { ITrackingHistoryRecord } from '@interfaces';
import React from 'react';

import s from './HistoryRecord.scss';

interface HistoryRecordProps {
    historyData: ITrackingHistoryRecord;
}

export const HistoryRecord = (props: HistoryRecordProps) => {
    const {
        historyData,
    } = props;

    return (
        <Row noPadding className={s.recordContainer}>
            <Column noPadding>
                <Row noPadding className={s.headerRow}>
                    <Column noPadding className={s.icon}>
                        {StreamerHistoryCommentTypeIcons[historyData.type]}
                    </Column>
                    <Column noPadding className={s.scout}>
                        {`[${historyData.user.username}]`}
                    </Column>
                    <Column noPadding className={s.time}>
                        {new Date(historyData.timestamp).toLocaleString()}
                    </Column>
                </Row>
                <Row noPadding className={s.comment}>
                    {`${historyData.comment}`}
                </Row>
            </Column>
        </Row>
    )
}
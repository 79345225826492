import { Button, ComponentColor, Row, RowItemsJustification, ScrollableArea, Tab } from '@components';
import { StreamerHistoryCommentTypeIcons, StreamersHistoryMode, useModals, useStreamersHistory } from '@hooks';
import React from 'react';
import cn from 'classnames';

import { DDMMYYYY, RU } from '@const';
import { IHistoryRecord, IStreamerTwitchData, StreamerHistoryCommentType } from '@typings';
import s from './StreamersHistory.scss';
import { ImageDaLogo, ImageMaLogo } from '@assets/images/svg';
import { ContactHistory } from '../../modals';

type TStreamerData = Partial<Pick<IStreamerTwitchData, 'id' | 'name' | 'averageViewers' | 'registeredAt' | 'daLink' | 'maLink' | 'isActive' | 'maLinkDays'>>;

const AvgViewersStatus = {
    FISH: '🐁',
    GOLDEN_FISH: '🐼',
    DOLPHIN: '🦁',
    WHALE:'🐳',
}

const getAvgViewersStatus = (el: IHistoryRecord) => {
    if (!el.avgViewers) return '';

    if (el.avgViewers >= 2000) {
        return AvgViewersStatus.WHALE;
    } else if (el.avgViewers >= 1000) {
        return AvgViewersStatus.DOLPHIN;
    } else if (el.avgViewers >= 500) {
        return AvgViewersStatus.GOLDEN_FISH;
    } else {
        return AvgViewersStatus.FISH;
    }
}

export const StreamersHistory = () => {
    const {
        history,
        isEndReached,
        streamersHistoryMode,
        loadNextPage,
        switchStreamersHistoryMode,
    } = useStreamersHistory();

    const {
        showModal,
    } = useModals();

    const showContactHistory = (streamer: TStreamerData) => {
        if (streamer.name) {
            showModal({
                content: <ContactHistory streamer={streamer} />,
                options: {
                    bodyClassName: s.contactHistoryModalBody,
                    preventCloseOnShadow: false,
                }
            })
        }
    }

    const items = history.map((el, idx) => (
        <tr
            className={cn(s.tableRow, {
                [s.red]: el.type === StreamerHistoryCommentType.LINK_REMOVED,
                [s.green]: el.type === StreamerHistoryCommentType.LINK_SET,
                [s.yellow]: el.type === StreamerHistoryCommentType.REGISTRATION,
            })}
            key={idx}
            onClick={() => showContactHistory({
                name: el.streamer ?? '',
                id: el.streamerId,
                averageViewers: el.avgViewers ?? 0,
                registeredAt: el.registeredAt ?? 0,
                daLink: el.daLink,
                maLink: el.maLink,
                maLinkDays: el.maLinkDays,
                isActive: el.isActive,
            })}
        >
            <td className={s.tableRowCell}>{new Date(el.timestamp).toLocaleString(RU, DDMMYYYY)}</td>
            <td className={s.tableRowCell}>{el.streamer}</td>
            <td className={s.tableRowCell}>{el.avgViewers}</td>
            <td className={s.tableRowCell}>{getAvgViewersStatus(el)}</td>
            <td className={s.tableRowCell}>
                {!!el.daLink &&
                    <a
                        className={s.link}
                        href={`https://donationalerts.com/r/${el.daLink.toLowerCase()}`}
                        target='_blank'
                    >
                        <div className={s.logo}><ImageDaLogo/></div>
                    </a>
                }
            </td>
            <td className={s.tableRowCell}>
                {!!el.maLink &&
                    <a
                        className={s.link}
                        href={
                            `https://memealerts.com/${el.maLink.toLowerCase()}`
                        }
                        target='_blank'
                    >
                        <div className={s.logo}><ImageMaLogo/></div>
                    </a>
                }
            </td>
            <td className={s.tableRowCell}>{`${StreamerHistoryCommentTypeIcons[el.type]} ${el.comment}`}</td>
            <td className={s.tableRowCell}>{el.user?.username ?? '-'}</td>
        </tr>
    ));

    return (
        <>
            <Row noColumnGap noPadding>
                <Tab
                    caption='History'
                    active={streamersHistoryMode === StreamersHistoryMode.HISTORY}
                    onClick={() => switchStreamersHistoryMode(StreamersHistoryMode.HISTORY)}
                />
                <Tab
                    caption='Comments'
                    active={streamersHistoryMode === StreamersHistoryMode.COMMUNICATION}
                    onClick={() => switchStreamersHistoryMode(StreamersHistoryMode.COMMUNICATION)}
                />
                <Tab
                    caption='Start/Stop'
                    active={streamersHistoryMode === StreamersHistoryMode.START_STOP}
                    onClick={() => switchStreamersHistoryMode(StreamersHistoryMode.START_STOP)}
                />
                <Tab
                    caption='Active'
                    active={streamersHistoryMode === StreamersHistoryMode.ACTIVE}
                    onClick={() => switchStreamersHistoryMode(StreamersHistoryMode.ACTIVE)}
                />
                <Tab
                    caption='Lost'
                    active={streamersHistoryMode === StreamersHistoryMode.LOST}
                    onClick={() => switchStreamersHistoryMode(StreamersHistoryMode.LOST)}
                />
                <Tab
                    caption='Payouts'
                    active={streamersHistoryMode === StreamersHistoryMode.PAYOUTS}
                    onClick={() => switchStreamersHistoryMode(StreamersHistoryMode.PAYOUTS)}
                />
                <Tab
                    caption='Registration'
                    active={streamersHistoryMode === StreamersHistoryMode.REGISTRATION}
                    onClick={() => switchStreamersHistoryMode(StreamersHistoryMode.REGISTRATION)}
                />
                <Tab
                    caption='Bonuses'
                    active={streamersHistoryMode === StreamersHistoryMode.BONUS}
                    onClick={() => switchStreamersHistoryMode(StreamersHistoryMode.BONUS)}
                />
            </Row>
            <ScrollableArea>
                <table className={s.table}>
                    <thead>
                        <tr className={s.tableHeader}>
                            <td className={s.tableHeaderCell}>Date</td>
                            <td className={s.tableHeaderCell}>Streamer</td>
                            <td className={s.tableHeaderCell}>Avg.Viewers</td>
                            <td className={s.tableHeaderCell}>Type</td>
                            <td className={s.tableHeaderCell}>DA</td>
                            <td className={s.tableHeaderCell}>MA</td>
                            <td className={s.tableHeaderCell}>Message</td>
                            <td className={s.tableHeaderCell}>Scout</td>
                        </tr>
                    </thead>
                    <tbody>
                        {items}
                    </tbody>
                </table>
            </ScrollableArea>
            <Row justification={RowItemsJustification.END}>
                <Button
                    color={ComponentColor.ACCENT}
                    disabled={isEndReached}
                    caption={'Загрузить еще'}
                    onClick={() => loadNextPage()}
                />
            </Row>
        </>
    )
}
import { useApi, useAuth, useLogger } from '@hooks';
import { useEffect, useState } from 'react';

export const useCurrentUser = () => {
    const { trace, debug } = useLogger({ target: 'useCurrentUser' });

    const {
        user: {
            useGetCurrentUserQuery,
        }
    } = useApi();

    const {
        token,
        clearToken,
    } = useAuth();

    const {
        data: currentUser,
        isLoading,
        isFetching,
        isError,
        isSuccess,
        error,
        refetch: refetchCurrentUser,
    } = useGetCurrentUserQuery(void(0), { skip: !token});

    const [ noCurrentUser, setNoCurrentUser ] = useState(false);

    useEffect(() => {
        setNoCurrentUser((!isLoading && !isFetching && isSuccess && !currentUser) || isError);
        if (isError && error && (error as any).status === 403) {
            debug(`Current token is invalid and will be removed`);
            clearToken();
        }
    }, [currentUser, isLoading, isFetching, isError, isSuccess]);

    useEffect(() => {
        trace('Mount');
        if (!token) {
            debug(`Current user load request is skipped because there is no token provided`);
            setNoCurrentUser(true);
        }
        return () => trace('Dismount');
    }, []);

    return {
        currentUser,
        noCurrentUser,
        state: {
            isLoading: isLoading || isFetching,
            isError,
            isSuccess,
        },
        refetchCurrentUser,
    }
}
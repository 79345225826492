import React from 'react';
import cn from 'classnames';

import s from './Row.scss';

export enum RowItemsAlignment {
    TOP = 'alignTop',
    BOTTOM = 'alignBottom',
    CENTER = 'alignCenter',
    STRETCH = 'alignStretch',
}

export enum RowItemsJustification {
    BEGIN = 'justifyBegin',
    END = 'justifyEnd',
    CENTER = 'justifyCenter',
    STRETCH = 'justifyStretch',
    SPACE_BETWEEN = 'justifySpaceBetween',
}

interface RowProps {
    alignment?: RowItemsAlignment;
    className?: string;
    grow?: boolean;
    justification?: RowItemsJustification;
    noColumnGap?: boolean;
    noPadding?: boolean;
}

export const Row = (props: React.PropsWithChildren<RowProps>) => {
    const {
        alignment = RowItemsAlignment.CENTER,
        children,
        className,
        grow = false,
        noPadding = false,
        noColumnGap = false,
        justification = RowItemsJustification.BEGIN,
    } = props;

    return (
        <div className={cn({
                [s.row]: true,
                [s[alignment]]: true,
                [s[justification]]: true,
                [s.grow]: grow,
                [s.noPadding]: noPadding,
                [s.noColumnGap]: noColumnGap,
            }, className)}>
            { children }
        </div>
    );
};

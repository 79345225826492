import React from 'react';

import s from './CenteredContent.scss';

export const CenteredContent = (props: React.PropsWithChildren) => {
    const {
        children,
    } = props;

    return (
        <div
            className={s.centeredContent}
        >
            {children}
        </div>
    );
};

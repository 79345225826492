import cn from 'classnames';
import React, { useEffect, useState } from 'react';

import s from './RoundedAvatar.scss';
import { noop } from '@common';

import NoAvatar from '@images/png/no-avatar.png';

export enum RoundedAvatarSize {
    EXTRA_SMALL = 'extra-small',
    SMALL = 'small',
    MEDIUM = 'medium',
    LARGE = 'large',
}

interface RoundedAvatarProps {
    className?: string;
    size?: RoundedAvatarSize;
    source?: string;
    style?: React.CSSProperties;
    onClick?: () => void;
}

export const RoundedAvatar = (props: RoundedAvatarProps) => {
    const {
        className,
        size = RoundedAvatarSize.MEDIUM,
        source,
        style = {},
        onClick = noop,
    } = props;

    const [isError, setIsError] = useState(false);

    const onImageLoadError = () => {
        setIsError(true);
    }

    useEffect(() => {
        setIsError(false);
    }, [source]);

    return (
        <div
            className={cn(
                s.roundedAvatar,
                s[size],
                {
                    [s.clickable]: onClick != undefined,
                },
                className,
            )}
            style={style}
            onClick={onClick}
        >
            <img
                className={s.roundedAvatarImage}
                src={(isError || !source) ? NoAvatar : source}
                onClick={onClick}
                onError={onImageLoadError}
            />
        </div>
    )
}
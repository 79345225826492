import { Column, Row } from '@components'
import { IStreamerStatus, IStreamerTwitchData } from '@typings'
import React from 'react'

import s from './StatusHistory.scss';

interface StatusHistoryProps {
    streamer: IStreamerTwitchData;
    statusHistory: Array<IStreamerStatus>;
}

export const StatusHistory = (props: StatusHistoryProps) => {
    const {
        streamer: {
            statusHistory = [],
        },
    } = props;

    const getAction = (record: IStreamerStatus): { formattedDate: string, actionText: string } => {
        const actionText = record.becameActiveAt > record.becameInactiveAt ? 'Стал активным' : 'Стал неактивным';
        const formattedDate = new Date(Math.max(record.becameActiveAt, record.becameInactiveAt)).toLocaleString();
        return {
            actionText,
            formattedDate,
        }
    }

    const historyRows = statusHistory.map((record, idx) => {
        const action = getAction(record);
        return (
            <Row key={idx} noPadding>
                <Column noPadding>{action.actionText}</Column>
                <Column noPadding>{action.formattedDate}</Column>
            </Row>
        );
    });

    return (
        <Column>
            {historyRows}
        </Column>
    )
}
import { useApi } from '@hooks/webapi'
import { ICommonStatistics, ICommonStatisticsRecord } from '@typings';
import { useEffect, useState } from 'react';

const PAGE_SIZE = 30;

export const useDailyStatistics = () => {
    const {
        scouts: {
            useLazyGetDailyStatisticPageQuery,
        }
    } = useApi();

    const [ loadDailyStatisticPage, { data: loadedDailyStatisticPage } ] = useLazyGetDailyStatisticPageQuery();

    const [ page, setPage ] = useState(1);
    const [ dailyStatistic, setDailyStatistic ] = useState<Array<ICommonStatisticsRecord>>([]);
    const [ isEndReached, setIsEndReached ] = useState(false);

    const loadNextPage = () => setPage(prev => prev++);

    useEffect(() => {
        loadDailyStatisticPage({ skip: (page - 1) * PAGE_SIZE, limit: PAGE_SIZE });
    }, [page]);

    useEffect(() => {
        if (loadedDailyStatisticPage?.data) {
            const newDailyStatistic = [...dailyStatistic.concat(loadedDailyStatisticPage.data)];
            if (newDailyStatistic.length >= loadedDailyStatisticPage.total) {
                setIsEndReached(true);
            }
            setDailyStatistic(newDailyStatistic);
        }
    }, [loadedDailyStatisticPage]);

    return {
        dailyStatistic,
        isEndReached,
        loadNextPage,
    }
}
import { cpApi } from './cp-base.api';

export const cpAuthApi = cpApi.injectEndpoints({
    endpoints: build => ({
        auth: build.query<Record<string, string>, { username: string, password: string }>({
            query: (credentials: { username: string, password: string }) => ({
                url: 'auth/login',
                method: 'POST',
                body: credentials
            }),
        }),
    })
});

import { Button, Column, ColumnItemsAlignment, ComponentColor, IconSelector, Input, InputType, Row, RowItemsAlignment, RowItemsJustification, ScrollableArea, TextArea } from '@components';
import React, { useEffect, useState } from 'react';

import { StreamerHistoryCommentTypeIcons, StreamerHistoryCommentTypeText, useCurrentUser, useLogger, useModals, useStreamerHistory } from '@hooks';
import { IconCrossLine, IconTwitchSolid, IconTwitchTrackerSolid, ImageDaLogo, ImageMaLogo } from '@images/svg';
import { IStreamerTwitchData } from '@interfaces';
import s from './ContactHistory.scss';
import { HistoryRecord } from './components';
import { DDMMYYYY, RU } from '@const';
import { StreamerHistoryCommentType } from '@typings';
import { getAvgViewersStatus, getReward, getShortNumberText, getStreamerStatus } from '@common';
import cn from 'classnames';

type TStreamersProps = 'id' | 'name' | 'averageViewers' | 'registeredAt' | 'daLink' | 'maLink' | 'isActive' |
    'maLinkDays' |  'maLinkDaysOff' | 'isMAConnected' | 'becameActiveAt' | 'becameInactiveAt';
type TStreamerData = Partial<Pick<IStreamerTwitchData, TStreamersProps>>;

interface ContactHistoryProps {
    streamer: TStreamerData;
}

const Icons = Object.values(StreamerHistoryCommentTypeIcons).slice(0, 5);
Icons.push(Object.values(StreamerHistoryCommentTypeIcons)[11]);
Icons.push(Object.values(StreamerHistoryCommentTypeIcons)[9]);

export const ContactHistory = (props: ContactHistoryProps) => {
    const {
        streamer,
    } = props;

    const {
        currentUser,
    } = useCurrentUser();

    const {
        history,
        selectedCommentType,
        payoutValue,
        changeSelectedCommentType,
        changePayoutValue,
        createHistoryRecord,
        loadHistory,
    } = useStreamerHistory();

    const streamerTotalPayout = history
        .filter(r => r.type === StreamerHistoryCommentType.PAY_TO_STREAMER)
        .map(r => r.payout ?? 0)
        .reduce((acc, val) => acc =+ val, 0);

    const scoutTotalPayout = history
        .filter(r => r.type === StreamerHistoryCommentType.PAYOUT)
        .map(r => r.payout ?? 0)
        .reduce((acc, val) => acc =+ val, 0);

    const scoutPayoutActivated = streamer.isActive && streamer.maLinkDays && streamer.maLinkDays >= 90;

    const logger = useLogger({ target: ContactHistory.name });
    const { closeModal } = useModals();

    const [comment, setComment] = useState<string>();

    const onSelectionChange = (selection: number) => {
        changeSelectedCommentType(selection);
    }

    const onSendClick = () => {
        if (comment && currentUser && streamer.id) {
            const type = selectedCommentType === 5
                ? StreamerHistoryCommentType.PAY_TO_STREAMER
                : selectedCommentType === 6
                    ? StreamerHistoryCommentType.PAYOUT
                    : selectedCommentType;

            const payout = (selectedCommentType === 5 || selectedCommentType === 6) ? payoutValue : 0;

            createHistoryRecord({
                comment,
                type,
                user: currentUser,
                timestamp: Date.now(),
                streamerId: streamer.id,
                payout,
            });
        }
    }

    const onCloseClick = () => {
        closeModal();
    }

    const historyRecords = history.map((record, idx) => <HistoryRecord historyData={record} key={idx} />);
    const payoutToStreamerSum = history
        .filter(r => r.type === StreamerHistoryCommentType.PAY_TO_STREAMER && !!r.payout)
        .map(r => r.payout)
        .reduce((acc = 0, val) => acc += val ?? 0, 0);

    useEffect(() => {
        if (streamer.id) {
            loadHistory(streamer.id);
        }
    }, [])

    return (
        <Row
            noPadding
            alignment={RowItemsAlignment.STRETCH}
            className={s.contactHistory}
        >
            <Column grow className={s.comments}>
                {/* Streamer name, Avg Viewers and Status */}
                <Row className={s.commentsStreamerName}>
                    <Column noPadding>
                        <a
                            className={s.link}
                            href={`https://twitchtracker.com/${streamer.name}`}
                            target='_blank'
                        >
                            {streamer?.name}
                        </a>
                    </Column>
                    <Column noPadding>
                        {getAvgViewersStatus(streamer)}
                    </Column>
                    <Column noPadding grow/>
                    <Column noPadding>
                        <div className={s.streamerInfo}>
                            {getShortNumberText(streamer.averageViewers ?? 0)} viewers /
                            <span className={s[getStreamerStatus(streamer).toLowerCase()]}> {getStreamerStatus(streamer)}</span>
                        </div>
                      </Column>
                </Row>


                <Row className={s.commentsDates} alignment={RowItemsAlignment.STRETCH}>
                    <Column noPadding className={s.commentsSubtitles} alignment={ColumnItemsAlignment.BEGIN}>
                        <Row noColumnGap noPadding>
                            Reward: {getReward(streamer)}
                        </Row>
                        <Row noColumnGap noPadding>
                            Streamer reward: {streamerTotalPayout}
                        </Row>
                        <Row noColumnGap noPadding>
                            Scout reward: {scoutTotalPayout}
                        </Row>
                    </Column>

                    <Column noPadding grow/>

                    <Column noPadding  className={s.commentsSubtitles} alignment={ColumnItemsAlignment.BEGIN}>
                        <Row noColumnGap noPadding justification={RowItemsJustification.END}>
                            Registered: {streamer.registeredAt ? new Date(streamer.registeredAt).toLocaleString(RU, DDMMYYYY) : '-'}
                        </Row>
                        <Row noColumnGap noPadding justification={RowItemsJustification.END}>
                            Joined: {streamer.becameActiveAt ? new Date(streamer.becameActiveAt).toLocaleString(RU, DDMMYYYY) : '-'}
                        </Row>
                        <Row noColumnGap noPadding justification={RowItemsJustification.END}>
                            Days: {streamer.maLinkDays ?? '0'}
                        </Row>
                    </Column>
                </Row>

                <Row className={s.commentsDays} alignment={RowItemsAlignment.STRETCH}>
                    <Column noPadding alignment={ColumnItemsAlignment.BEGIN} className={s.commentsSubtitles}>
                        <Row noColumnGap noPadding>
                            Days Off: {streamer.maLinkDaysOff ?? '0'}
                        </Row>
                        <Row noColumnGap noPadding>
                            Lost: {streamer.becameInactiveAt ? new Date(streamer.becameInactiveAt).toLocaleString(RU, DDMMYYYY) : '-'}
                        </Row>
                    </Column>

                    <Column noPadding grow/>

                    <Column noPadding>
                        <Row noPadding className={s.commentsButtons}>
                            <Column noPadding>
                                <a
                                    className={s.link}
                                    href={`https://twitch.tv/${streamer.name?.toLowerCase()}`}
                                    target='_blank'
                                >
                                    <div className={cn(s.logo, s.logoTwitch)}><IconTwitchSolid/></div>
                                </a>
                            </Column>

                            <Column noPadding>
                                <a
                                    className={s.link}
                                    href={`https://twitchtracker.com/${streamer.name?.toLowerCase()}`}
                                    target='_blank'
                                >
                                    <div className={s.logo}><IconTwitchTrackerSolid/></div>
                                </a>
                            </Column>

                            { streamer.daLink &&
                                <Column noPadding>
                                    <a
                                        className={s.link}
                                        href={`https://donationalerts.com/r/${streamer.daLink.toLowerCase()}`}
                                        target='_blank'
                                    >
                                        <div className={s.logo}><ImageDaLogo/></div>
                                    </a>
                                </Column>
                            }

                            {streamer.maLink &&
                                <Column noPadding>
                                    <a
                                        className={s.link}
                                        href={`https://memealerts.com/${streamer.maLink.toLowerCase()}`}
                                        target='_blank'
                                    >
                                        <div className={s.logo}><ImageMaLogo/></div>
                                    </a>
                                </Column>
                            }
                        </Row>
                    </Column>
                </Row>

                {/* <Row className={s.commentsSubtitle}>
                    <Column noPadding>
                        Avg.Viewers: {streamer.averageViewers ?? 'N/A'},
                    </Column>
                    <Column noPadding>
                        Registered: {streamer.registeredAt ? new Date(streamer.registeredAt).toLocaleString(RU, DDMMYYYY) : 'N/A'}{!!payoutToStreamerSum ? ',' : ''}
                    </Column>
                    { !!payoutToStreamerSum &&
                    <Column noPadding>
                        Total payout: {payoutToStreamerSum}
                    </Column>
                    }
                </Row> */}
                <ScrollableArea>
                    {historyRecords}
                </ScrollableArea>
            </Column>
            <Column grow className={s.form}>
                <Row className={s.formTitle} noPadding>
                    {'Комментарий'}
                </Row>
                <Row>
                    <TextArea maxRows={13} onChange={(val) => setComment(val?.toString())} />
                </Row>
                <Row>
                    <IconSelector
                        defaultSelection={-1}
                        icons={Icons}
                        onSelectionChange={onSelectionChange}
                    />
                    {selectedCommentType > 4 &&
                        <Input
                            placeholder='сумма'
                            initialValue={payoutValue}
                            className={s.payoutValue}
                            type={InputType.NUMBER}
                            onChange={(val) => changePayoutValue(Number(val))}
                        />
                    }
                </Row>
                <Row className={s.formCommentType}>
                    {StreamerHistoryCommentTypeText[selectedCommentType]}
                </Row>
                <Row>
                    <Button
                        color={ComponentColor.ACCENT}
                        caption={'Отправить'}
                        disabled={!comment || !currentUser || selectedCommentType < 0 ||
                            (selectedCommentType === 5 && !payoutValue) ||
                            (selectedCommentType === 6 && !payoutValue) ||
                            (selectedCommentType === 6 && !scoutPayoutActivated)
                        }
                        onClick={onSendClick}
                    />
                </Row>
            </Column>
            <div
                className={s.contactHistoryClose}
                onClick={onCloseClick}
            >
                <IconCrossLine />
            </div>
        </Row>
    )
}
import { SortType } from '@typings'

import s from './SortIcon.scss';
import React from 'react';
import { IconChevronDownLine, IconChevronUpLine } from '@assets/images/svg';
import { noop } from '@common';

interface ISortIconProps {
    sortType?: SortType;
    onClick?: () => void;
}

export const SortIcon = (props: ISortIconProps) => {
    const {
        sortType = SortType.ASC,
        onClick = noop,
    } = props;

    return (
        <div className={s.sortIcon} onClick={onClick}>
            {
                sortType === SortType.ASC
                    ? <IconChevronUpLine/>
                    : <IconChevronDownLine/>
            }
        </div>
    )
}
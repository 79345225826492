import { Button, Column, InputType, Row } from '@components'
import React from 'react'
import s from './DaTopUpdate.scss';
import { useAdmin } from '@hooks';

export const DaTopUpdate = () => {
    const {
        setDataFile,
        uploadData,
    } = useAdmin();

    const onSelectFile = (e: React.ChangeEvent<HTMLInputElement>) => {
        const files = e.target.files;
        if (!files || !files.length) return;

        setDataFile(files[0]);
    }

    const onUploadButton = () => {
        uploadData();
    }

    return (
        <Column>
            <Row>
                <input
                    type={InputType.FILE}
                    className={s.fileSelector}
                    onChange={onSelectFile}
                />
            </Row>
            <Row>
                <Button caption='Загрузить данные' onClick={onUploadButton}/>
            </Row>
        </Column>
    )
}
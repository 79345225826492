import { Input, InputType, InputValueType } from '@components';
import { Viewport, Column } from '@components';
import { CenteredContent } from '@layouts';
import React, { useState } from 'react'
import { IconEnterLine, IconKeyLine, IconUserLine } from '@images/svg';

import s from './Login.scss';
import { useLogin } from '@hooks';

export const Login = () => {
    const [username, setUsername] = useState<InputValueType>();
    const [password, setPassword] = useState<InputValueType>();

    const { login } = useLogin();

    const onLoginAction = () => {
        if (username && password) {
            login(username.toString(), password.toString());
        }
    }

    return (
        <Viewport>
            <CenteredContent>
                <Column>
                    <Input
                        caption={'User name'}
                        tag={<IconUserLine/>}
                        onChange={(v) => setUsername(v)}
                    />
                    <br/>
                    <Input
                        action={<IconEnterLine/>}
                        caption={'Password'}
                        tag={<IconKeyLine/>}
                        type={InputType.PASSWORD}
                        onAction={onLoginAction}
                        onChange={(v) => setPassword(v)}
                    />
                </Column>
            </CenteredContent>
        </Viewport>
    )
}
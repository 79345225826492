import { IStreamerBinding, IStreamerTwitchData, IStreamersListPageLoadParams, IStreamersListPageLoadResult } from '@interfaces';
import { cpApi } from './cp-base.api';

export const cpTaskLogApi = cpApi.injectEndpoints({
    endpoints: build => ({
        getLastTaskCompletionDateTime: build.query<number, string>({
            query: (task: string) => ({
                url: 'task-log/last-completion',
                method: 'POST',
                body: { task },
            }),
        }),
    })
});

import React from 'react';
import cn from 'classnames';

import s from './StatusBarPanel.scss';

interface StatusBarPanelProps {
    content?: string | JSX.Element;
}

export const StatusBarPanel = (props: StatusBarPanelProps) => {
    const {
        content,
    } = props;

    return (
        <div className={cn({
            [s.statusBarPanel]: true,
        })}>
            { content }
        </div>
    );
};

import React, { useEffect, useState } from 'react';
import cn from 'classnames';
import s from './IconSelector.scss';
import { Row } from '@components/containers';
import { noop } from '@common';

interface IconSelectorProps {
    defaultSelection?: number;
    icons: Array<string | JSX.Element>;
    onSelectionChange?: (selection: number) => void;
}

export const IconSelector = (props: IconSelectorProps) => {
    const {
        defaultSelection = 0,
        icons,
        onSelectionChange = noop,
    } = props;

    const [selectedIcon, setSelectedIcon] = useState(defaultSelection);

    const items = icons.map((icon, idx) => (
        <div
            key={idx}
            className={cn(
                s.iconSelectorIcon,
                { [s.iconSelectorIconActive]: idx === selectedIcon }
            )}
            onClick={() => setSelectedIcon(idx)}
        >
            {icon}
        </div>
    ));

    useEffect(() => {
        onSelectionChange(selectedIcon);
    }, [selectedIcon]);

    return (
        <Row className={s.iconSelector} noPadding>
            {items}
        </Row>
    )
}
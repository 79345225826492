import { configureStore } from "@reduxjs/toolkit";
import {
    cpApi,
    cpAdminApi,
    cpAuthApi,
    cpStreamersApi,
    cpScoutsApi,
    cpStreamerHistoryApi,
    cpUserApi,
    cpRegisteredStreamersApi,
    cpInProgressStreamersApi,
    cpDoneStreamersApi,
    cpTrackingHistoryApi,
    cpTaskLogApi,
} from "./cp";

export const store = configureStore({
    reducer: {
        [cpApi.reducerPath]: cpApi.reducer,
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: false,
        }).concat(cpApi.middleware),
});

export type RootState = ReturnType<typeof store.getState>;

export const api = {
    ...cpApi,
    admin: {
        ...cpAdminApi,
    },
    auth: {
        ...cpAuthApi,
    },
    streamersHistory: {
        ...cpStreamerHistoryApi,
    },
    doneStreamers: {
        ...cpDoneStreamersApi,
    },
    inProgressStreamers: {
        ...cpInProgressStreamersApi,
    },
    registeredStreamers: {
        ...cpRegisteredStreamersApi,
    },
    scouts: {
        ...cpScoutsApi,
    },
    streamers: {
        ...cpStreamersApi,
    },
    taskLog: {
        ...cpTaskLogApi,
    },
    trackingHistory: {
        ...cpTrackingHistoryApi,
    },
    user: {
        ...cpUserApi,
    },
};

import { useApi, useLogger } from '@hooks';
import { IStreamerHistoryRecord } from '@interfaces';
import { useEffect, useState } from 'react';

const PAGE_SIZE = 100;

export enum StreamersHistoryMode {
    HISTORY,
    COMMUNICATION,
    START_STOP,
    ACTIVE,
    LOST,
    PAYOUTS,
    REGISTRATION,
    BONUS,
}

export const useStreamersHistory = () => {
    const {
        streamersHistory: {
            useLazyGetStreamersHistoryPageQuery,
        }
    } = useApi();

    const logger = useLogger({ target: useStreamersHistory.name });

    const [ loadPage, { data: loadedPage, isFetching } ] = useLazyGetStreamersHistoryPageQuery();

    const [ streamersHistoryMode, setStreamersHistoryMode ] = useState(StreamersHistoryMode.HISTORY);

    const [ history, setHistory] = useState<Array<IStreamerHistoryRecord>>([]);
    const [ page, setPage ] = useState(1);
    const [ isEndReached, setIsEndReached ] = useState(false);

    const loadNextPage = () => setPage(prev => prev + 1);

    const switchStreamersHistoryMode = (mode: StreamersHistoryMode) => setStreamersHistoryMode(mode);

    useEffect(() => {
        loadPage({
            skip: PAGE_SIZE * (page - 1),
            limit: PAGE_SIZE,
            historyMode: streamersHistoryMode,
        });
    }, [page]);

    useEffect(() => {
        setIsEndReached(false);
        setHistory([]);
        if (page !== 1) {
            setPage(1);
        } else {
            loadPage({
                skip: PAGE_SIZE * (page - 1),
                limit: PAGE_SIZE,
                historyMode: streamersHistoryMode,
            });
        }
    }, [streamersHistoryMode]);

    useEffect(() => {
        setHistory(history.concat(loadedPage ?? []));
        if (loadedPage && (!loadedPage.length || loadedPage.length < PAGE_SIZE)) {
            setIsEndReached(true);
        }
    }, [loadedPage]);

    return {
        history,
        isEndReached,
        page,
        streamersHistoryMode,
        loadNextPage,
        switchStreamersHistoryMode,
    }
}
import { IStreamerHistoryRecord } from '@interfaces';
import { LoadPageParams, LoadPageParamsWith } from '@typings';
import { cpApi } from './cp-base.api';
import { StreamersHistoryMode } from '@hooks';

export const cpStreamerHistoryApi = cpApi.injectEndpoints({
    endpoints: build => ({
        getStreamerHistory: build.query<Array<IStreamerHistoryRecord>, string>({
            query: (streamer: string) => ({
                url: `streamer-history/${streamer}`,
                method: 'GET',
            }),
            providesTags: ["StreamerHistory"],
        }),
        createStreamerHistoryRecord: build.mutation<IStreamerHistoryRecord, IStreamerHistoryRecord>({
            query: (data: IStreamerHistoryRecord) => ({
                url: `streamer-history`,
                method: 'POST',
                body: data,
            }),
            invalidatesTags: ["StreamerHistory", "Streamers"],
        }),
        getStreamersHistoryPage: build.query<Array<IStreamerHistoryRecord>, LoadPageParamsWith<{historyMode?: StreamersHistoryMode}>>({
            query: (params: LoadPageParams) => ({
                url: `streamers-history/page`,
                method: 'POST',
                body: params,
            }),
            providesTags: ["StreamersHistory"],
        }),
    })
});

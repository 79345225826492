import { IUser } from './IUser';

/** The interface reflects streamer's state related to MA CRM */
export interface IStreamerStatus {
    /** Current scout object: { _id, username } */
    scout?: IUser;

    /** Is the streamer processed by a scout right now */
    isInProcess: boolean;

    /** Timestamp when the streamer was added to processing */
    addedToProcessAt: number;

    /** Is the streamer active? I.e. the streamer has MAConnected flag equals to 1 */
    isActive: boolean;

    /** Timestamp when the streamer became active */
    becameActiveAt: number;

    /** Timestamp when the streamer became inactive */
    becameInactiveAt: number;

    /** Number of days the streamer had had MA link installed */
    maLinkDays: number;

    /** Number of days the streamer had had MA link uninstalled (if it was installed earlier) */
    maLinkDaysOff: number;
}

import { Badge, Column, ComponentColor, Row, ScrollableArea } from '@components';
import { StreamerHistoryCommentTypeIcons, useCurrentUser, useLogger, useModals } from '@hooks';
import React, { useEffect, useRef } from 'react';

import { ImageDaLogo, ImageMaLogo } from '@assets/images/svg';
import { DaTopStatus, getAvgViewersStatus, getAvgViewersStatuses, getDaStatus, getReward } from '@common';
import { DDMMYY, RU } from '@const';
import { IComplexFilters, useStreamers } from '@hooks/workbench/Streamers';
import { IStreamerTwitchData } from '@interfaces';
import { MAConnectionType, SortType, SortableField } from '@typings';
import cn from 'classnames';
import { CommonStatistics, ComplexFilter, Paginator, SearchBar, SortIcon } from '../../components';
import { BindingConfirmation, ContactHistory, FreeingConfirmation } from '../../modals';
import s from './Streamers.scss';

const StreamerStateCaptions = [
    'Unreg',
    'Nonact',
    'Active',
    'Lost',
]

export const Streamers = () => {
    const logger = useLogger({ target: Streamers.name });

    const { showModal, closeModal } = useModals();

    const {
        selectedStreamer,
        streamers,
        page,
        pagesCount,
        sortField,
        sortType,
        scouts,
        deselectStreamer,
        selectStreamer,
        selectPage,
        bindStreamerToScout,
        setQuery,
        setComplexFilter,
        setSortField,
        setSortType,
        unbindStreamerFromScout,
    } = useStreamers();

    const {
        currentUser,
    } = useCurrentUser();

    const selectedStreamerRef = useRef(selectedStreamer);

    const getLastContactDateTime = (streamer: IStreamerTwitchData) => {
        if (streamer.history?.length) {
            const record = streamer.history[0];
            const icon = StreamerHistoryCommentTypeIcons[record.type];
            const source = record.type < 5 || record.type === 9 || record.type === 11
                ? record.scout?.username
                    ? `[${record.scout?.username}]`
                    : record.user?.username
                        ? `[${record.user?.username}]`
                        : ''
                : '[Событие]';

            const date = new Date(record.timestamp).toLocaleDateString(RU, DDMMYY);
            return `${date} ${icon} ${source} `;
        } else {
            return '';
        }
    }

    const getLastComment = (streamer: IStreamerTwitchData) => {
        return streamer.history?.length
            ? streamer.history[0].comment
            : '-';
    }

    const showContactHistory = (streamer: IStreamerTwitchData) => {
        logger.debug(streamer);
        showModal({
            content: <ContactHistory streamer={{ ...streamer, maLink: streamer.maLink || streamer.maLinkFromDb }} />,
            options: {
                bodyClassName: s.contactHistoryModalBody,
                preventCloseOnShadow: false,
            }
        })
    }

    const showBindingConfirmation = (streamer: IStreamerTwitchData) => {
        selectStreamer(streamer);
        showModal({
            content: <BindingConfirmation/>,
            options: {
                withHeader: true,
                caption: `Внимание ${currentUser?.username}`,
                bodyClassName: s.bindingConfirmation,
                buttons: [
                    { caption: 'Да', onClick: () => okBindingConfirmation() },
                    { caption: 'Нет', onClick: () => cancelBindingConfirmation(), color: ComponentColor.WHITE }
                ]
            }
        })
    }

    const okBindingConfirmation = () => {
        if (currentUser?.id && selectedStreamerRef.current?.id) {
            bindStreamerToScout({
                streamerId: selectedStreamerRef.current.id,
                scoutId: currentUser?.id,
            });
        }
        closeModal();
    }

    const cancelBindingConfirmation = () => {
        deselectStreamer();
        closeModal();
    }

    const showFreeingConfirmation = (streamer: IStreamerTwitchData) => {
        selectStreamer(streamer);
        showModal({
            content: <FreeingConfirmation/>,
            options: {
                withHeader: true,
                caption: `Внимание ${currentUser?.username}`,
                bodyClassName: s.bindingConfirmation,
                buttons: [
                    { caption: 'Да', onClick: () => okFreeingConfirmation() },
                    { caption: 'Нет', onClick: () => cancelFreeingConfirmation(), color: ComponentColor.WHITE }
                ]
            }
        })
    }

    const okFreeingConfirmation = () => {
        if (currentUser?.id && selectedStreamerRef.current?.id) {
            unbindStreamerFromScout({
                streamerId: selectedStreamerRef.current.id,
                scoutId: currentUser?.id,
            });
        }
        closeModal();
    }

    const cancelFreeingConfirmation = () => {
        deselectStreamer();
        closeModal();
    }

    const onSortableFieldHeaderClick = (field: SortableField) => {
        if (sortField === field) {
            setSortType(prev => prev * -1);
        } else {
            setSortField(field);
            if (field === 'daTopPlace') {
                setSortType(SortType.ASC);
            } else {
                setSortType(SortType.DESC);
            }
        }
    }

    const onComplexFilterChanged = (filters: IComplexFilters) => {
        setComplexFilter({...filters});
    }

    const getPageReward = () => {
        return streamers.map(s => getReward(s)).reduce((acc, val) => acc += val, 0);
    }

    const tableRows = streamers.map((streamer, idx) => (
        <tr
            className={cn(
                s.tableRow,
                { [s.green]: streamer.isActive },
                { [s.orange]: !streamer.isMAConnected && streamer.scout?.username === currentUser?.username},
                { [s.yellow]: streamer.isMAConnected && !streamer.becameActiveAt},
                { [s.red]: !!streamer.becameActiveAt && !streamer.isActive },
            )}
            key={streamer.id}
            onClick={() => showContactHistory(streamer)}
        >
            <td className={s.tableRowCell}>{(page - 1) * 100 + idx + 1}</td>
            <td className={s.tableRowCell} onClick={(e) => e.stopPropagation()}>
                <a
                    className={s.link}
                    href={`https://twitchtracker.com/${streamer.name.toLowerCase()}`}
                    target='_blank'
                >
                    {streamer.name}
                </a>
            </td>
            <td className={s.tableRowCell}>{streamer.averageViewers}</td>
            <td className={s.tableRowCell} onClick={(e) => e.stopPropagation()}>
                <a
                    className={s.link}
                    href={`https://twitch.tv/${streamer.name.toLowerCase()}`}
                    target='_blank'
                >
                    {getAvgViewersStatus(streamer)}
                </a>
            </td>
            <td className={s.tableRowCell}>{getDaStatus(streamer)}</td>
            <td className={s.tableRowCell}>{getReward(streamer)}</td>
            <td className={s.tableRowCell} >
                {!!streamer.daTopPlace && streamer.daTopPlace < 999999 ? streamer.daTopPlace : '-'}
            </td>
            <td className={s.tableRowCell} onClick={(e) => e.stopPropagation()}>
                {!!streamer.daLink &&
                    <a
                        className={s.link}
                        href={`https://donationalerts.com/r/${streamer.daLink.toLowerCase()}`}
                        target='_blank'
                    >
                        <div className={s.logo}><ImageDaLogo/></div>
                    </a>
                }
            </td>
            <td className={s.tableRowCell} onClick={(e) => e.stopPropagation()}>
                {(!!streamer.maLink || !!streamer.maLinkFromDb) &&
                    <a
                        className={s.link}
                        href={
                            `https://memealerts.com/${streamer.maLink
                                ? streamer.maLink.toLowerCase()
                                : streamer.maLinkFromDb.toLowerCase()}`
                        }
                        target='_blank'
                    >
                        <div className={s.logo}><ImageMaLogo/></div>
                    </a>
                }
            </td>
            <td className={s.tableRowCell}>{streamer.registeredAt ? new Date(streamer.registeredAt).toLocaleDateString(RU, DDMMYY) : ''}</td>
            <td className={s.tableRowCell}>{streamer.becameActiveAt ? new Date(streamer.becameActiveAt).toLocaleDateString(RU, DDMMYY) : ''}</td>
            <td className={s.tableRowCell}>{streamer.becameActiveAt && !streamer.isActive ? new Date(streamer.becameInactiveAt).toLocaleDateString(RU, DDMMYY) : ''}</td>
            <td className={s.tableRowCell}>{getLastContactDateTime(streamer)}{getLastComment(streamer)}</td>
            <td className={s.tableRowCell}>
                <Badge caption='Contact' color={ComponentColor.WHITE} onClick={() => showContactHistory(streamer)} />
            </td>
            <td className={s.tableRowCell} onClick={(e) => e.stopPropagation()}>
                {streamer?.scout
                    ? streamer?.scout?.username === currentUser?.username
                        ? <Badge caption='Stop' color={ComponentColor.DANGER} onClick={() => showFreeingConfirmation(streamer)}/>
                        : streamer?.scout?.username
                    : !streamer?.isActive && streamer.isMAConnected !== MAConnectionType.CONNECTED
                        ? <Badge caption='Start' color={ComponentColor.SUCCESS} onClick={() => showBindingConfirmation(streamer)}/>
                        : null
                }
            </td>
        </tr>
    ));

    useEffect(() => {
        selectedStreamerRef.current = selectedStreamer;
    }, [selectedStreamer]);

    return (
        <>
            <Row noPadding>
                <Column grow noPadding>
                    <Row className={s.topBar} noPadding>
                        <SearchBar onChange={(v) => setQuery(v?.toString() ?? '')}/>
                        <ComplexFilter
                            daTypesValues={Object.values(DaTopStatus)}
                            statesValues={StreamerStateCaptions}
                            typesValues={getAvgViewersStatuses()}
                            scouts={scouts?.map(s => s.username)}
                            onFilterChange={onComplexFilterChanged}
                        />
                        <CommonStatistics />
                        <Column grow noPadding/>
                        <Paginator
                            pagesCount={pagesCount}
                            activePage={page}
                            onPageSelect={selectPage}
                        />
                    </Row>
                </Column>
            </Row>
            <ScrollableArea>
                <table className={s.table}>
                    <thead>
                        <tr className={s.tableHeader}>
                            <td className={s.tableHeaderCell}>
                                {'#'}
                            </td>
                            <td className={s.tableHeaderCell}>
                                {'Streamer'}
                            </td>
                            <td className={s.tableHeaderCell}>
                                <div className={s.tableHeaderCellSortable} onClick={() => onSortableFieldHeaderClick('averageViewers')}>
                                    {'Viewers'}
                                    {sortField === 'averageViewers' && <SortIcon sortType={sortType} />}
                                </div>
                            </td>
                            <td className={s.tableHeaderCell}>
                                {'Type'}
                            </td>
                            <td className={s.tableHeaderCell}>
                                {'Value'}
                            </td>
                            <td className={s.tableHeaderCell}>
                                {'Reward'}
                            </td>
                            <td className={s.tableHeaderCell}>
                                <div className={s.tableHeaderCellSortable} onClick={() => onSortableFieldHeaderClick('daTopPlace')}>
                                    {'Top'}
                                    {sortField === 'daTopPlace' && <SortIcon sortType={sortType} />}
                                </div>
                            </td>
                            <td className={s.tableHeaderCell}>
                                {'DA'}
                            </td>
                            <td className={s.tableHeaderCell}>
                                {'MA'}
                            </td>
                            <td className={s.tableHeaderCell}>
                                <div className={s.tableHeaderCellSortable} onClick={() => onSortableFieldHeaderClick('registeredAt')}>
                                    {'Registered'}
                                    {sortField === 'registeredAt' && <SortIcon sortType={sortType} />}
                                </div>
                            </td>
                            <td className={s.tableHeaderCell}>
                                <div className={s.tableHeaderCellSortable} onClick={() => onSortableFieldHeaderClick('becameActiveAt')}>
                                    {'Join'}
                                    {sortField === 'becameActiveAt' && <SortIcon sortType={sortType} />}
                                </div>
                            </td>
                            <td className={s.tableHeaderCell}>
                                <div className={s.tableHeaderCellSortable} onClick={() => onSortableFieldHeaderClick('becameInactiveAt')}>
                                    {'Lost'}
                                    {sortField === 'becameInactiveAt' && <SortIcon sortType={sortType} />}
                                </div>
                            </td>
                            <td className={s.tableHeaderCell}>
                                {'Comment'}
                            </td>
                            <td className={s.tableHeaderCell}>
                                {'Contact'}
                            </td>
                            <td className={s.tableHeaderCell}>
                                {'Scout'}
                            </td>
                        </tr>
                    </thead>
                    <tbody>
                        {tableRows}
                    </tbody>
                </table>
                <Row className={s.pageReward}>
                    Page Reward: {getPageReward()}
                </Row>
            </ScrollableArea>
        </>
    )
}

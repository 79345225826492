import { useState } from 'react';

interface SideBarParams {
    collapsible?: boolean;
    collapsedByDefault?: boolean;
}

export const useSideBar = (params: SideBarParams) => {
    const {
        collapsible = false,
        collapsedByDefault = false,
    } = params;

    const [ isCollapsed, setIsCollapsed ] = useState(collapsedByDefault);

    const toggleCollapse = () => {
        if (collapsible) {
            setIsCollapsed(prev => !prev);
        }
    }

    return {
        isCollapsed,
        toggleCollapse,
    }
}
import { Column, Row } from '@components'
import { StreamerHistoryCommentTypeIcons } from '@hooks';
import { IStreamerHistoryRecord } from '@interfaces';
import React from 'react'

import s from './HistoryRecord.scss';
import { StreamerHistoryCommentType } from '@typings';

interface HistoryRecordProps {
    historyData: IStreamerHistoryRecord;
}

export const HistoryRecord = (props: HistoryRecordProps) => {
    const {
        historyData,
    } = props;

    return (
        <Row noPadding className={s.recordContainer}>
            <Column noPadding>
                <Row noPadding className={s.headerRow}>
                    <Column noPadding className={s.icon}>
                        {StreamerHistoryCommentTypeIcons[historyData.type]}
                    </Column>
                    <Column noPadding className={s.scout}>
                        {`[${historyData.user?.username ?? historyData.scout?.username ?? 'Событие'}]`}
                    </Column>
                    <Column noPadding className={s.time}>
                        {new Date(historyData.timestamp).toLocaleString()}
                    </Column>
                </Row>
                <Row noPadding className={s.comment}>
                    {`${historyData.comment} ${
                        (historyData.type === StreamerHistoryCommentType.PAY_TO_STREAMER ||
                            historyData.type === StreamerHistoryCommentType.PAYOUT)
                                ? `(${historyData.payout})`
                                : ''
                        }`}
                </Row>
            </Column>
        </Row>
    )
}
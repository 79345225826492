// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".SearchBar__input--bw4Tp {\n  font-size: 14px;\n}", "",{"version":3,"sources":["webpack://./src/views/modes/ScoutCrm/components/SearchBar/SearchBar.scss"],"names":[],"mappings":"AAAA;EACI,eAAA;AACJ","sourcesContent":[".input {\r\n    font-size: 14px;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"input": "SearchBar__input--bw4Tp"
};
export default ___CSS_LOADER_EXPORT___;

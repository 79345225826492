import { useLogger } from '@hooks';
import { useApi } from '@hooks/webapi';
import { IRegisteredStreamer } from '@interfaces';
import { useEffect, useState } from 'react';

const PAGE_SIZE = 100;

export const useRegisteredStreamers = () => {
    const logger = useLogger({ target: useRegisteredStreamers.name });
    const {
        registeredStreamers: {
            useLazyGetRegisteredStreamersListPageQuery,
        }
    } = useApi();

    const [ loadPage, { data: loadedPage, isFetching } ] = useLazyGetRegisteredStreamersListPageQuery();

    const [ streamers, setStreamers ] = useState<IRegisteredStreamer[]>([]);
    const [ selectedStreamer, setSelectedStreamer ] = useState<IRegisteredStreamer | null>(null)
    const [ totalCount, setTotalCount ] = useState(0);
    const [ pagesCount, setPagesCount ] = useState(0);
    const [ page, setPage ] = useState(1);
    const [ query, setQuery ] = useState('');

    const selectPage = (page: number)  => setPage(page);
    const selectStreamer = (streamer: IRegisteredStreamer) => setSelectedStreamer(streamer);
    const deselectStreamer = () => setSelectedStreamer(null);

    useEffect(() => {
        loadPage({
            skip: PAGE_SIZE * (page - 1),
            limit: PAGE_SIZE,
        });
    }, [page]);

    useEffect(() => {
        if (page !== 1) {
            setPage(1);
        } else {
            loadPage({
                skip: PAGE_SIZE * (page - 1),
                limit: PAGE_SIZE,
                query,
            });
        }
    }, [query]);

    useEffect(() => {
        if (loadedPage) {
            setStreamers(loadedPage.data);
            setTotalCount(loadedPage.total);
            setPagesCount(Math.ceil(loadedPage.total / 100));
        }
    }, [loadedPage]);

    return {
        selectedStreamer,
        streamers,
        page,
        pagesCount,
        query,
        totalCount,
        deselectStreamer,
        selectPage,
        selectStreamer,
        setQuery,
    }
}
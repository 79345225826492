import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { App } from './App';
import { DOM_ROOT_CONTAINER_ID } from './const';
import { store } from './store';

const container = document.getElementById(DOM_ROOT_CONTAINER_ID);
if (!container) throw new Error(`Root container with id='${DOM_ROOT_CONTAINER_ID}' is missing in the index.html file`);

const root = createRoot(container);

root.render(
    <React.StrictMode>
        <Provider store={store}>
            <BrowserRouter>
                <App />
            </BrowserRouter>
        </Provider>
    </React.StrictMode>
);

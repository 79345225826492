import { IStreamerBinding, IStreamerTwitchData, IStreamersListPageLoadParams } from '@interfaces';
import { LoadPageParamsWith, PageOf } from '@typings';
import { cpApi } from './cp-base.api';

export const cpDoneStreamersApi = cpApi.injectEndpoints({
    endpoints: build => ({
        getDoneStreamersListPage: build.query<PageOf<IStreamerTwitchData>, LoadPageParamsWith<IStreamersListPageLoadParams>>({
            query: (params: LoadPageParamsWith<IStreamersListPageLoadParams>) => ({
                url: 'streamers/page',
                method: 'POST',
                body: params,
            }),
            providesTags: ["DoneStreamers"],
        }),
        bindDoneStreamerToScout: build.mutation<IStreamerTwitchData, IStreamerBinding>({
            query: (params: IStreamerBinding) => ({
                url: 'streamers/bind-scout',
                method: 'POST',
                body: params,
            }),
            invalidatesTags: ["DoneStreamers"],
        }),
        unbindDoneStreamerFromScout: build.mutation<IStreamerTwitchData, IStreamerBinding>({
            query: (params: IStreamerBinding) => ({
                url: 'streamers/bind-scout',
                method: 'DELETE',
                body: params,
            }),
            invalidatesTags: ["DoneStreamers"],
        }),
        payToScout: build.mutation<IStreamerTwitchData, IStreamerBinding>({
            query: (params: IStreamerBinding) => ({
                url: 'streamers/pay-to-scout',
                method: 'POST',
                body: params,
            }),
            invalidatesTags: ["DoneStreamers"],
        }),
    })
});

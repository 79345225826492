import { useEffect, useState } from 'react';

export type TextValueType = string | ReadonlyArray<string> | number | undefined;

interface TextParams {
    defaultValue?: TextValueType;
    readonly?: boolean;
    debounceTime?: number;
    onChange?: (value: TextValueType) => void;
}

let timer: NodeJS.Timeout;

export const useText = (params: TextParams) => {
    const {
        defaultValue = '',
        readonly,
        debounceTime = 0,
        onChange,
    } = params;

    const [value, setValue] = useState<TextValueType>(defaultValue);
    const [length, setLength] = useState<TextValueType>(defaultValue);

    const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        setValue(event.target.value);
        setLength(event.target.value.length);
        if (debounceTime > 0) {
            clearTimeout(timer);
            timer = setTimeout(() => {
                onChange && onChange(event.target.value);
            }, debounceTime);
        } else {
            onChange && onChange(event.target.value);
        }
    }

    useEffect(() => {
        if (defaultValue || defaultValue === '') {
            setValue(defaultValue);
            onChange && onChange(defaultValue);
        }
    }, [defaultValue]);

    return {
        value,
        length,
        onChange: readonly ? () => {} : handleChange,
    }

}
import { ScrollableArea } from "@components";
import { DDMMYYYY, RU } from "@const";
import { useDailyStatistics } from "@hooks/workbench/Streamers";
import React from "react";
import s from "./DailyStatistic.scss";

export const DailyStatisic = () => {
    const { dailyStatistic, isEndReached, loadNextPage } = useDailyStatistics();

    const records = dailyStatistic.map((r) => (
        <tr>
            <td>{new Date(r.timestamp).toLocaleString(RU, DDMMYYYY)}</td>
            <td>{r.total}</td>
            <td>{r.unregistered}</td>
            <td>{r.nonactive}</td>
            <td>{r.active}</td>
            <td>{r.lost}</td>
            <td>{r.big}</td>
            <td>{r.medium}</td>
            <td>{r.small}</td>
            <td>{r.tiny}</td>
            <td>{r.bigActive}</td>
            <td>{r.mediumActive}</td>
            <td>{r.smallActive}</td>
            <td>{r.tinyActive}</td>
            <td>{r.bigLost}</td>
            <td>{r.mediumLost}</td>
            <td>{r.smallLost}</td>
            <td>{r.tinyLost}</td>
        </tr>
    ));

    return (
        <>
            <ScrollableArea>
                <table className={s.table}>
                    <thead>
                        <tr>
                            <td colSpan={2}></td>
                            <td colSpan={4}>Status</td>
                            <td colSpan={4}>By Type</td>
                            <td colSpan={4}>Active</td>
                            <td colSpan={4}>Lost</td>
                        </tr>
                        <tr>
                            <td></td>
                            <td>Total</td>
                            <td>Unreg</td>
                            <td>Nonact</td>
                            <td>Active</td>
                            <td>Lost</td>
                            <td>🐳</td>
                            <td>🦁</td>
                            <td>🐼</td>
                            <td>🐁</td>
                            <td>🐳</td>
                            <td>🦁</td>
                            <td>🐼</td>
                            <td>🐁</td>
                            <td>🐳</td>
                            <td>🦁</td>
                            <td>🐼</td>
                            <td>🐁</td>
                        </tr>
                    </thead>
                    <tbody>{records}</tbody>
                </table>
            </ScrollableArea>
        </>
    );
};

import React from 'react'
import cn from 'classnames';

import s from './MenuItem.scss';
import { MenuOrientation } from './Menu';
import { ComponentSize } from '@components';

export enum MenuItemIconPosition {
    LEFT = 'iconPositionLeft',
    RIGHT = 'iconPositionRight',
    TOP = 'iconPositionTop',
    BOTTOM = 'iconPositionBottom',
}

export enum MenuItemAlignment {
    BEGIN = 'alignmentBegin',
    END = 'alignmentEnd',
    CENTER = 'alignmentCenter',
}

export enum MenuItemActiveMarkerPosition {
    TOP = 'markerPositionTop',
    RIGHT = 'markerPositionRight',
    BOTTOM = 'markerPositionBottom',
    LEFT = 'markerPositionLeft',
}

export interface MenuItemProps {
    activeMarkerPosition?: MenuItemActiveMarkerPosition;
    alignment?: MenuItemAlignment;
    caption?: string;
    disabled?: boolean;
    hidden?: boolean;
    icon?: JSX.Element;
    iconPosition?: MenuItemIconPosition;
    iconSize?: ComponentSize;
    isActive?: boolean;
    parentOrientation?: MenuOrientation;
    onClick?: () => void;
}

export const MenuItem = (props:MenuItemProps) => {
    const {
        caption,
        icon,
        isActive,
        disabled,
        hidden = false,
        iconPosition = MenuItemIconPosition.LEFT,
        iconSize = ComponentSize.SMALL,
        alignment = MenuItemAlignment.BEGIN,
        parentOrientation,
        activeMarkerPosition = (
            parentOrientation === MenuOrientation.VERTICAL
                ? MenuItemActiveMarkerPosition.LEFT
                : MenuItemActiveMarkerPosition.BOTTOM
        ),
        onClick,
    } = props;

    return (
        hidden
            ? null
            : (<div
                className={cn({
                    [s.menuItem]: true,
                    [s[iconPosition]]: true,
                    [s[iconSize]]: true,
                    [s.clickable]: !!onClick && !disabled,
                    [s.menuItemActive]: isActive,
                    [s[alignment]]: true,
                    [s[activeMarkerPosition]]: true,
                    [s.disabled]: disabled,
                })}
                onClick={onClick}
            >
                { !!icon &&
                    <div className={s.menuItemIcon}>
                        { icon }
                    </div>
                }
                { !!caption &&
                    <div className={s.menuItemCaption}>
                        { caption }
                    </div>
                }
            </div>
        )
    )
}
import { useApi } from '@hooks/webapi';
import { useState } from 'react'

export const useAdmin = () => {
    const {
        admin: {
            useLazyUploadDaTopDataQuery,
        }
    } = useApi();

    const [uploadFile] = useLazyUploadDaTopDataQuery();

    const [daTopFile, setDaTopFile] = useState<File>();

    const setDataFile = (file: File) => {
        setDaTopFile(file);
    }

    const uploadData = () => {
        if (!daTopFile) return;
        const form = new FormData();
        form.append('file', daTopFile);
        uploadFile(form);
    }

    return {
        setDataFile,
        uploadData,
    }
}

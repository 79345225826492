import { Admin, Bonuses, DaTopUpdate, InProgress, Login, MarketerCrm, ScoutCrm, Statistics, Streamers, StreamersHistory, StreamersHold, Workbench } from "@views";
import React from "react";
import { Navigate, RouteObject } from "react-router-dom";
import { ProtectedRoute } from './ProtectedRoute';

export const Routing: RouteObject[] = [
    {
        path: "/",
        element: <ProtectedRoute><Workbench /></ProtectedRoute>,
        children: [
            {
                path: "scout",
                element: <ScoutCrm />,
                children: [
                    {
                        path: "streamers",
                        element: <Streamers />,
                    },
                    {
                        path: "in-progress",
                        element: <InProgress />,
                    },
                    {
                        path: "history",
                        element: <StreamersHistory />
                    },
                    {
                        path: "statistics",
                        element: <Statistics />,
                    },
                    {
                        path: "",
                        element: <Navigate to="streamers" replace />,
                    },
                ],
            },
            {
                path: "marketer",
                element: <MarketerCrm />,
                children: [
                    {
                        path: "streamers-hold",
                        element: <StreamersHold />,
                    },
                    {
                        path: "",
                        element: <Navigate to="streamers-hold" replace />,
                    },
                ],
            },
            {
                path: "admin",
                element: <Admin />,
                children: [
                    {
                        path: "da-top-update",
                        element: <DaTopUpdate />
                    },
                    {
                        path: "",
                        element: <Navigate to="da-top-update" replace />,
                    },
                ],
            },
            {
                path: "",
                element: <Navigate to="scout" replace />,
            },
        ],
    },
    {
        path: '/login',
        element: <Login/>
    }
];

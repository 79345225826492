import { useLogger } from '@hooks';
import { useApi } from '@hooks/webapi';
import { IStreamerTwitchData } from '@interfaces';
import { SortType, SortableField } from '@typings';
import { useEffect, useState } from 'react';

const PAGE_SIZE = 100;

export interface IComplexFilters {
    states?: Array<number>;
    daTypes?: Array<number>;
    types?: Array<number>;
    scout?: string;
}

export const useStreamers = () => {
    const logger = useLogger({ target: useStreamers.name });
    const {
        streamers: {
            useLazyGetStreamersListPageQuery,
            useBindStreamerToScoutMutation,
            useUnbindStreamerFromScoutMutation,
        },
        taskLog: {
            useGetLastTaskCompletionDateTimeQuery,
        },
        user: {
            useLoadScoutsListQuery,
        }
    } = useApi();

    const { data: lastDbUpdateTimestamp } = useGetLastTaskCompletionDateTimeQuery('ParseTwitchTracker');
    const [ loadPage, { data: loadedPage, isFetching } ] = useLazyGetStreamersListPageQuery();
    const [ bindStreamerToScout ] = useBindStreamerToScoutMutation();
    const [ unbindStreamerFromScout ] = useUnbindStreamerFromScoutMutation();
    const { data: scouts } = useLoadScoutsListQuery();

    const [ streamers, setStreamers ] = useState<IStreamerTwitchData[]>([]);
    const [ selectedStreamer, setSelectedStreamer ] = useState<IStreamerTwitchData | null>(null)
    const [ totalCount, setTotalCount ] = useState(0);
    const [ pagesCount, setPagesCount ] = useState(0);
    const [ page, setPage ] = useState(1);
    const [ query, setQuery ] = useState('');
    const [ complexFilter, setComplexFilter ] = useState<IComplexFilters>();
    const [ lastDbUpdate, setLastDbUpdate ] = useState<string>();
    const [ sortField, setSortField ] = useState<SortableField>('averageViewers');
    const [ sortType, setSortType ] = useState<SortType>(SortType.DESC);

    const selectPage = (page: number)  => setPage(page);
    const selectStreamer = (streamer: IStreamerTwitchData) => setSelectedStreamer(streamer);
    const deselectStreamer = () => setSelectedStreamer(null);

    useEffect(() => {
        loadPage({
            skip: PAGE_SIZE * (page - 1),
            limit: PAGE_SIZE,
            query,
            statusFilter: complexFilter?.states,
            typesFilters: complexFilter?.types,
            daTypesFilters: complexFilter?.daTypes,
            scoutFilter: complexFilter?.scout,
            sortField,
            sortType,
        });
    }, [page]);

    useEffect(() => {
        if (page !== 1) {
            setPage(1);
        } else {
            loadPage({
                skip: PAGE_SIZE * (page - 1),
                limit: PAGE_SIZE,
                query,
                statusFilter: complexFilter?.states,
                typesFilters: complexFilter?.types,
                daTypesFilters: complexFilter?.daTypes,
                scoutFilter: complexFilter?.scout,
                sortField,
                sortType,
            });
        }
    }, [query, complexFilter, sortField, sortType]);

    useEffect(() => {
        if (loadedPage) {
            setStreamers(loadedPage.data);
            setTotalCount(loadedPage.total);
            setPagesCount(Math.ceil(loadedPage.total / 100));
        }
    }, [loadedPage]);

    useEffect(() => {
        if (lastDbUpdateTimestamp) {
            setLastDbUpdate(new Date(lastDbUpdateTimestamp).toLocaleString());
        }
    }, [lastDbUpdateTimestamp]);

    return {
        lastDbUpdate,
        selectedStreamer,
        sortField,
        sortType,
        streamers,
        page,
        pagesCount,
        query,
        totalCount,
        scouts,
        bindStreamerToScout,
        deselectStreamer,
        selectPage,
        selectStreamer,
        setQuery,
        setSortField,
        setComplexFilter,
        setSortType,
        unbindStreamerFromScout,
    }
}
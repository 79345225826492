export enum StreamerHistoryCommentType {
    NO_CONTACT,
    OK,
    WAIT,
    REFUSE,
    ATTENTION,
    LINK_SET,
    LINK_REMOVED,
    START_PROCESS,
    STOP_PROCESS,
    PAYOUT,
    REGISTRATION,
    PAY_TO_STREAMER,
    BONUS,
}

import { IconChevronLeftLine, IconChevronRightLine } from '@images/svg';
import React from 'react';

import s from './CollapseButton.scss';

interface CollapseButtonProps {
    isCollapsed?: boolean;
    toggleCollapse?: () => void;
}

export const CollapseButton = (props: CollapseButtonProps) => {
    const {
        isCollapsed,
        toggleCollapse,
    } = props;
    return (
        <div className={s.collapseButton} onClick={toggleCollapse}>
            { isCollapsed
                ? <IconChevronRightLine />
                : <IconChevronLeftLine />
            }
        </div>
    )
}
import { Column, Row, RowItemsAlignment, StatusBar, StatusBarPanel, Viewport } from '@components';
import { useCurrentUser } from '@hooks';
import { IUser } from '@interfaces';
import { Header } from '@views';
import React from 'react'

interface BaseLayoutProps {
    header: React.ReactNode;
    currentUser?: IUser;
}

export const BaseLayout = (props: React.PropsWithChildren<BaseLayoutProps>) => {
    const {
        children,
        header,
    } = props;

    const {
        currentUser,
    } = useCurrentUser();

    return (
        <Viewport>
            <Column grow noPadding>
                {header}
                <Row grow noPadding alignment={RowItemsAlignment.STRETCH}>
                    {children}
                </Row>
                <Row noPadding>
                    <StatusBar>
                        <StatusBarPanel content={`Version: 1.2.0`} />
                        <StatusBarPanel content={`Current user: ${currentUser?.username}`} />
                    </StatusBar>
                </Row>
            </Column>
        </Viewport>
    )
}
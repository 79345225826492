import { useLogger } from '@hooks';
import { useApi } from '@hooks/webapi';
import { StreamerHistoryCommentType, IStreamerHistoryRecord } from '@typings';
import { useEffect, useState } from 'react';

export const StreamerHistoryCommentTypeIcons = {
    /** Users statuses */
    [StreamerHistoryCommentType.NO_CONTACT]: '☁️',
    [StreamerHistoryCommentType.OK]: '✅',
    [StreamerHistoryCommentType.WAIT]: '⏰',
    [StreamerHistoryCommentType.REFUSE]: '💩',
    [StreamerHistoryCommentType.ATTENTION]: '⚠️',
    /** Service events statuses */
    [StreamerHistoryCommentType.LINK_SET]: '❇️',
    [StreamerHistoryCommentType.LINK_REMOVED]: '🚫',
    [StreamerHistoryCommentType.START_PROCESS]: '⭐️',
    [StreamerHistoryCommentType.STOP_PROCESS]: '⛔️',
    [StreamerHistoryCommentType.PAYOUT] : '💰',
    [StreamerHistoryCommentType.REGISTRATION]: '😎',
    [StreamerHistoryCommentType.PAY_TO_STREAMER]: '💲',
    [StreamerHistoryCommentType.BONUS]: '💵',
}

export const StreamerHistoryCommentTypeText = [
    'Нет контакта',
    'Стример согласился',
    'Стример думает',
    'Стример отказался',
    'Стример требует внимания',
    'Выплата стримеру',
    'Выплата скауту'
];

export const useStreamerHistory = () => {
    const {
        streamersHistory: {
            useLazyGetStreamerHistoryQuery,
            useCreateStreamerHistoryRecordMutation,
        }
    } = useApi();
    const logger = useLogger();

    const [ loadHistory, { data: loadedHistory } ] = useLazyGetStreamerHistoryQuery();
    const [ createHistoryRecord ] = useCreateStreamerHistoryRecordMutation();

    const [selectedCommentType, setSelectedCommentType] = useState(0);
    const [history, setHistory] = useState<Array<IStreamerHistoryRecord>>([]);
    const [payoutValue, setPayoutValue] = useState(0);

    const changeSelectedCommentType = (type: number) => setSelectedCommentType(type);

    const changePayoutValue = (val: number) => setPayoutValue(val);

    useEffect(() => {
        if (loadedHistory?.length) {
            setHistory(loadedHistory);
        }
    }, [loadedHistory]);

    return {
        history,
        selectedCommentType,
        payoutValue,
        changeSelectedCommentType,
        changePayoutValue,
        createHistoryRecord,
        loadHistory,
    }
}
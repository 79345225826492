export enum SortOrder {
    ASC,
    DESC,
}

export const minIndex = (arr: Array<number>): number => {
    const minValue = Math.min(...arr);
    return arr.indexOf(minValue);
}

export const alternateArrays = <T>(arr1: Array<T>, arr2: Array<T>) => {
    const result: Array<T> = [];
    const minLength = Math.min(arr1.length, arr2.length);
    for (let i = 0; i < minLength; i++) {
        result.push(arr1[i], arr2[i]);
    }
    result.push(...arr1.slice(minLength), ...arr2.slice(minLength));
    return result;
}

export const sortObjects = <T>(arr: Array<T>, prop: keyof T, order: SortOrder = SortOrder.ASC) => {
    return order === SortOrder.ASC
        ? arr.sort((a, b) => a[prop] > b[prop] ? 1 : a[prop] === b[prop] ? 0 : -1)
        : arr.sort((a, b) => a[prop] > b[prop] ? -1 : a[prop] === b[prop] ? 0 : 1);
}

import React from 'react'

import s from './Badge.scss';
import { ComponentColor } from '@components/common';
import { noop } from '@common';
import cn from 'classnames';

interface BadgeProps {
    caption: string;
    color?: ComponentColor;
    disabled?: boolean;
    onClick?: () => void;
}

export const Badge = (props: BadgeProps) => {
    const {
        caption,
        color = ComponentColor.ACCENT,
        disabled = false,
        onClick = noop,
    } = props;

    const badgeClasses = cn({
        [s.badge]: true,
        [s.badgeDisabled]: disabled,
        [s[color]]: true,
    });

    return (
        <div className={badgeClasses} onClick={onClick}>
            <div className={s.badgeCaption}>
                {caption}
            </div>
        </div>
    )
}
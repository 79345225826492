import { useEffect } from 'react';

interface ClickOutsideParams {
	controlledElementRef: React.MutableRefObject<HTMLElement | null>;
	handler: () => void;
}


export const useClickOutside = (params: ClickOutsideParams) => {
	const {
		controlledElementRef,
		handler,
	} = params;

	useEffect(() => {
		if (controlledElementRef.current) {
			const onClickOutside = (event: MouseEvent) => {
				if (!controlledElementRef.current?.contains(event.target as Node)) {
					handler();
				}
			};

			document.addEventListener('click', onClickOutside);

			return () => {
				document.removeEventListener('click', onClickOutside);
			};
		}
	}, [controlledElementRef.current]);
};

import { IUser } from './IUser';

export interface IRegisteredStreamerCommon {
    id: string;
    name: string,
    username: string,
    createdAt: number,
    avatar?: string,
    channelLink?: string;
    daLink?: string;
    twitchLink?: string;
    youtubeLink?: string;
    altTelegramLink?: string;
    telegramLink?: string;
    mainStreamingPlatform?: string,
    maLinkInstalledAt?: number,
    lastStreamAt?: number,
    lastStickerAt?: number,
    manager?: Omit<IUser, 'channel' | '_id'>,
}
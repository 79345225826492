import { IChannel, IRegisteredStreamer, ISearchParams } from '@interfaces';
import { LoadPageParamsWith, PageOf } from '@typings';
import { cpApi } from './cp-base.api';

export const cpRegisteredStreamersApi = cpApi.injectEndpoints({
    endpoints: build => ({
        getRegisteredStreamersListPage: build.query<PageOf<IRegisteredStreamer>, LoadPageParamsWith<ISearchParams>>({
            query: (params: LoadPageParamsWith<ISearchParams>) => ({
                url: 'registered-streamers/page',
                method: 'POST',
                body: params,
            }),
            providesTags: ["RegisteredStreamers"],
        }),
        updateRegisteredStreamerChannel: build.mutation<IRegisteredStreamer, { streamerId: string, channel: Partial<IChannel> }>({
            query: ({ streamerId, channel }: { streamerId: string, channel: Partial<IChannel> }) => ({
                url: `registered-streamers/channel/${streamerId}`,
                method: 'PUT',
                body: channel,
            }),
            invalidatesTags: ["RegisteredStreamers"],
        })
    })
});

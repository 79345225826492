import { Row, Tab } from '@components';
import { useLogger } from '@hooks';
import React from 'react';

import { StatisticMode, useStatistic } from '@hooks/workbench/Streamers';
import { DailyStatisic, ScoutsStatistic } from './tabs';

export const Statistics = () => {
    const logger = useLogger({ target: Statistics.name });

    const {
        statisticMode,
        switchStatisticMode,
    } = useStatistic();

    return (
        <>
            <Row noColumnGap noPadding>
                <Tab
                    caption='Daily Statistic'
                    active={statisticMode === StatisticMode.DAILY_STATISTIC}
                    onClick={() => switchStatisticMode(StatisticMode.DAILY_STATISTIC)}
                />
                <Tab
                    caption='Scouts Statistic'
                    active={statisticMode === StatisticMode.SCOUTS_STATISTIC}
                    onClick={() => switchStatisticMode(StatisticMode.SCOUTS_STATISTIC)}
                />
            </Row>
            { statisticMode === StatisticMode.SCOUTS_STATISTIC && <ScoutsStatistic />}
            { statisticMode === StatisticMode.DAILY_STATISTIC && <DailyStatisic />}
        </>
    )
}

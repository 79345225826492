import { Badge, Column, ComponentColor, Row } from '@components';
import { IPayoutSummary, IStreamerTwitchData, MAConnectionType } from '@typings';
import React from 'react';
import cn from 'classnames';

import s from './ScoutBonuses.scss';
import { noop } from '@common';

interface ScoutBonusesProps {
    scoutName: string;
    data: Array<IStreamerTwitchData>;
    showPayButton?: boolean;
    onPayClick?: (streamer: IStreamerTwitchData) => void;
    onHistoryClick?: (streamer: IStreamerTwitchData) => void;
}

const MAStatus = {
    CONNECTED: '😊',
    NOT_CONNECTED: '👻',
    CONNECTED_BUT_NO_LINK: '🥵',
}

const AvgViewersStatus = {
    FISH: '🐟',
    GOLDEN_FISH: '🐠',
    DOLPHIN: '🐬',
    WHALE:'🐳',
}

export const ScoutBonuses = (props: ScoutBonusesProps) => {
    const {
        scoutName,
        data = [],
        showPayButton = false,
        onPayClick = noop,
        onHistoryClick = noop,
    } = props;

    const payout = data
        .filter(s => s.maLinkDays && s.maLinkDays >= 90)
        .map(s => {
            let sum: number;
            if (s.averageViewers >= 2000) {
                sum = 100000;
            } else if (s.averageViewers >= 1000) {
                sum = 30000;
            } else if (s.averageViewers >= 500) {
                sum = 10000;
            } else {
                sum = 5000;
            }
            return sum;
        })
        .reduce((acc = 0, sum) => acc + sum, 0);

const total = data
    .map(s => {
        let sum: number;
        if (s.averageViewers >= 2000) {
            sum = 100000;
        } else if (s.averageViewers >= 1000) {
            sum = 30000;
        } else if (s.averageViewers >= 500) {
            sum = 10000;
        } else {
            sum = 5000;
        }
        return sum;
    })
    .reduce((acc = 0, sum) => acc + sum, 0);

    const payoutSummary: IPayoutSummary = {
        streamersCount: data.length,
        payoutSum: payout,
        totalPayoutSum: total,
    }

    const getMAStatus = (streamer: IStreamerTwitchData) => streamer.isMAConnected === 0
        ? MAStatus.NOT_CONNECTED
        : streamer.isMAConnected === 1
            ? MAStatus.CONNECTED
            : MAStatus.CONNECTED_BUT_NO_LINK;

    const getAvgViewersStatus = (streamer: IStreamerTwitchData) => {
        if (streamer.averageViewers >= 2000) {
            return AvgViewersStatus.WHALE;
        } else if (streamer.averageViewers >= 1000) {
            return AvgViewersStatus.DOLPHIN;
        } else if (streamer.averageViewers >= 500) {
            return AvgViewersStatus.GOLDEN_FISH;
        } else {
            return AvgViewersStatus.FISH;
        }
    }

    const getBonusSize = (streamer: IStreamerTwitchData) => {
        if (streamer.averageViewers >= 2000) {
            return 100000;
        } else if (streamer.averageViewers >= 1000) {
            return 30000;
        } else if (streamer.averageViewers >= 500) {
            return 10000;
        } else {
            return 5000;
        }
    }

    const tableRows = data.map((streamer, idx) => (
        <tr
            className={cn(
                s.tableRow,
                { [s.green]: streamer.isMAConnected === MAConnectionType.CONNECTED && (streamer.maLinkDays && streamer.maLinkDays < 90)},
                { [s.orange]: streamer.isMAConnected && (streamer.maLinkDays && streamer.maLinkDays >= 90)},
                { [s.red]: streamer.isMAConnected === MAConnectionType.CONNECTED_BUT_HAS_NO_LINK },
            )}
            key={streamer.id}
        >
            <td className={s.tableRowCell}>{idx + 1}</td>
            <td className={s.tableRowCell}>
                <a
                    className={s.link}
                    href={`https://twitchtracker.com/${streamer.name.toLowerCase()}`}
                    target='_blank'
                >
                    {streamer.name}
                </a>
            </td>
            <td className={s.tableRowCell}>{getMAStatus(streamer)}</td>
            <td className={s.tableRowCell}>{getAvgViewersStatus(streamer)}</td>
            <td className={s.tableRowCell}>{streamer.registeredAt ? new Date(streamer.registeredAt).toLocaleDateString() : ''}</td>
            <td className={s.tableRowCell}>{streamer.becameActiveAt ? new Date(streamer.becameActiveAt).toLocaleDateString() : ''}</td>
            <td className={s.tableRowCell}>{streamer.maLinkDays}</td>
            <td className={s.tableRowCell}>{streamer?.maLinkDaysOff ? streamer.maLinkDaysOff : ''}</td>
            <td className={s.tableRowCell}>{getBonusSize(streamer)}</td>
            <td className={s.tableRowCell}>
                <div className={s.tableRowCellActions}>
                    { showPayButton && streamer.isMAConnected && (streamer.maLinkDays && streamer.maLinkDays >= 90) &&
                        <Badge caption='Pay' color={ComponentColor.DANGER} onClick={() => onPayClick(streamer)}/>
                    }
                    <Badge caption='History' color={ComponentColor.WARNING} onClick={() => onHistoryClick(streamer)}/>
                </div>
            </td>
        </tr>
    ));

    return (
        <Column>
            <Row className={s.scoutNameRow}>
                {scoutName}
            </Row>
            <Row noPadding>
                <table className={s.table}>
                    <thead>
                        <tr className={s.tableHeader}>
                            <td className={s.tableHeaderCell}>{'#'}</td>
                            <td className={s.tableHeaderCell}>{'Streamer'}</td>
                            <td className={s.tableHeaderCell}>{'MA'}</td>
                            <td className={s.tableHeaderCell}>{'Status'}</td>
                            <td className={s.tableHeaderCell}>{'Registered'}</td>
                            <td className={s.tableHeaderCell}>{'Join'}</td>
                            <td className={s.tableHeaderCell}>{'Days'}</td>
                            <td className={s.tableHeaderCell}>{'DaysOff'}</td>
                            <td className={s.tableHeaderCell}>{'Bonus'}</td>
                            <td className={s.tableHeaderCell}>{'Action'}</td>
                        </tr>
                    </thead>
                    <tbody>
                        {tableRows}
                    </tbody>
                </table>
            </Row>
            <Row className={s.summaryRow}>
                <Row noPadding className={s.summaryRowCell}>Bonus streamer: <span className={s.summaryRowCellCount}>{payoutSummary?.streamersCount}</span></Row>
                <Row noPadding className={s.summaryRowCell}>Bonus: <span className={s.summaryRowCellCount}>{payoutSummary?.totalPayoutSum} р.</span></Row>
                <Row noPadding className={s.summaryRowCell}>To pay: <span className={s.summaryRowCellCount}>{payoutSummary?.payoutSum} р.</span></Row>
            </Row>
        </Column>
    )
}
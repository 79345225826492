// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Viewport__viewport--nDJbB {\n  flex: 1;\n  display: flex;\n  flex-direction: column;\n}", "",{"version":3,"sources":["webpack://./src/components/containers/Viewport/Viewport.scss"],"names":[],"mappings":"AAAA;EACI,OAAA;EACA,aAAA;EACA,sBAAA;AACJ","sourcesContent":[".viewport {\r\n    flex: 1;\r\n    display: flex;\r\n    flex-direction: column;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"viewport": "Viewport__viewport--nDJbB"
};
export default ___CSS_LOADER_EXPORT___;

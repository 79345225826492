import { useDeviceDetection, useModals } from '@hooks';
import cn from 'classnames';
import React from 'react';

import s from './ModalWindow.scss';
import { Button, ComponentColor } from '@components';

export enum ModalWindowHeaderButtons {
    RETURN,
    CLOSE,
}

export interface ModalWindowButtonConfig {
    caption: string;
    color?: ComponentColor;
    onClick: () => void;
}

interface ModalWindowProps {
    buttons?: Array<ModalWindowButtonConfig>;
    caption?: string;
    className?: string;
    bodyClassName?: string;
    headerClassName?: string;
    containerClassName?: string;
    headerButtons?: Array<ModalWindowHeaderButtons>;
    withHeader?: boolean;
    preventCloseOnShadow?: boolean;
}

export type ModalOptions = Pick<
    ModalWindowProps,
    'withHeader' |
    'caption' |
    'className' |
    'bodyClassName' |
    'headerClassName' |
    'containerClassName' |
    'buttons' |
    'preventCloseOnShadow'
>;

export const ModalWindow = (props: React.PropsWithChildren<ModalWindowProps>) => {
    const {
        buttons = [],
        caption,
        className,
        bodyClassName,
        headerClassName,
        children,
        headerButtons = [ModalWindowHeaderButtons.CLOSE, ModalWindowHeaderButtons.RETURN],
        withHeader,
    } = props;

    const { isMobileLayoutRecommended } = useDeviceDetection();
    const { modals, closeModal } = useModals();

    const onModalWindowClick = (e: React.MouseEvent) => e.stopPropagation();

    const onCloseClick = () => {
        if (headerButtons.includes(ModalWindowHeaderButtons.CLOSE)) {
            closeModal();
        }
    }

    const onReturnClick = () => {
        if (headerButtons.includes(ModalWindowHeaderButtons.RETURN)) {
            closeModal();
        }
    }

    return (
        <div
            className={cn(
                s.modalWindow,
                {
                    [s.modalWindowMobileLayout]: isMobileLayoutRecommended,
                },
                className
            )}
            onClick={onModalWindowClick}
        >
            {/* Modal window header */}
            { withHeader &&
                <div className={cn(s.modalWindowHeader, headerClassName)}>
                    <div className={s.modalWindowHeaderReturnButton} onClick={onReturnClick}>
                        { modals.length > 1 && headerButtons.includes(ModalWindowHeaderButtons.CLOSE) && <div/>
                            // <IconArrowLeftLine />
                        }
                    </div>
                    <div className={s.modalWindowHeaderCaption}>
                        { caption }
                    </div>
                    <div className={s.modalWindowHeaderCloseButton} onClick={onCloseClick}>
                        { headerButtons.includes(ModalWindowHeaderButtons.CLOSE) && <div/>
                            // <IconCloseLine />
                        }
                    </div>
                </div>
            }
            {/* Modal window body */}
            <div className={cn(s.modalWindowBody, bodyClassName)}>
                { children }
            </div>
            {/* Modal window footer */}
            { buttons.length > 0 &&
                <div className={s.modalWindowFooter}>
                    { buttons.map((button, idx) => (
                        <Button
                            key={idx}
                            caption={button.caption}
                            color={button.color ?? ComponentColor.ACCENT}
                            onClick={button.onClick}
                        />
                    ))}
                </div>
            }
        </div>
    )
}
export enum ComponentSize {
    SMALL = 'sizeSmall',
    MEDIUM = 'sizeMedium',
    LARGE = 'sizeLarge',
}

export enum ComponentColor {
    WHITE = 'colorWhite',
    BLACK = 'colorBlack',
    SUCCESS = 'colorSuccess',
    WARNING = 'colorWarning',
    DANGER = 'colorDanger',
    INFO = 'colorInfo',
    ACCENT = 'colorAccent',
}
import React, { useEffect, useState } from 'react'

import s from './RadioItem.scss';
import { noop } from '@common';

interface RadioItemProps {
    caption: string;
    selected?: boolean;
    onChange?: (isSelected: boolean) => void;
}

export const RadioItem = (props: RadioItemProps) => {
    const {
        caption,
        selected = false,
        onChange = noop,
    } = props;

    const [ isSelected, setIsSelected ] = useState(selected);

    const onSelect = () => {
        setIsSelected(!isSelected);
    }

    useEffect(() => {
        onChange(isSelected);
    }, [isSelected]);

    useEffect(() => {
        setIsSelected(selected);
    }, [selected]);

    return (
        <div className={s.radioItem} onClick={onSelect}>
            <div className={s.radioItemCheck}>
                { selected && <div className={s.radioItemCheckFill}/> }
            </div>
            <div className={s.radioItemCaption}>{caption}</div>
        </div>
    )
}

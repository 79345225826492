import React from 'react';
import cn from 'classnames';

import s from './StatusBar.scss';

interface StatusBarProps {

}

export const StatusBar = (props: React.PropsWithChildren<StatusBarProps>) => {
    const {
        children,
    } = props;

    return (
        <div className={cn({
            [s.statusBar]: true,
        })}>
            { children }
        </div>
    );
};

import React from 'react'

import s from './Viewport.scss';

/**
 * Viewport is the base component that provides an area of whole screen
 */
export const Viewport = (props: React.PropsWithChildren) => {
    const {
        children,
    } = props;

    return (
        <div className={s.viewport}>
            {children}
        </div>
    );
};

import { useState } from 'react';

export enum StatisticMode {
    DAILY_STATISTIC,
    SCOUTS_STATISTIC,
}

export const useStatistic = () => {
    const [ statisticMode, setStatisticMode ] = useState<StatisticMode>(StatisticMode.DAILY_STATISTIC);

    const switchStatisticMode = (mode: StatisticMode) => setStatisticMode(mode);

    return {
        statisticMode,
        switchStatisticMode,
    }
}
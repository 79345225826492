import React, { createContext, useState } from 'react';
import { ModalOptions } from './components';

export interface ModalContentWithOptions<T = {}> {
    content: React.ReactNode;
    options?: ModalOptions;
    extras?: T,
}

interface ModalContextType {
    modals: Array<ModalContentWithOptions>;
    showModal: (modal: React.ReactNode | ModalContentWithOptions) => void;
    closeModal: () => void;
    clearModals: () => void;
}

const defaultModalOptions: ModalOptions = {
    withHeader: false,
}

export const ModalContext = createContext<ModalContextType>(undefined!);

export const ModalProvider = (props: React.PropsWithChildren) => {
    const { children } = props;

    const [modals, setModals] = useState<Array<ModalContentWithOptions>>([]);

    const showModal = (modal: React.ReactNode | ModalContentWithOptions) => {
        if ((modal as ModalContentWithOptions).options === undefined) {
            const content = modal as React.ReactNode;
            setModals(modals.concat({ content, options: defaultModalOptions }));
        } else {
            const contentWithOptions = modal as ModalContentWithOptions;
            setModals(modals.concat(contentWithOptions));
        }
    };

    const closeModal = () => {
        setModals(modals.slice(0, -1));
    };

    const clearModals = () => {
        setModals([]);
    };

    const value: ModalContextType = {
        modals,
        showModal,
        closeModal,
        clearModals,
    };

    return <ModalContext.Provider value={value}>{children}</ModalContext.Provider>;
}
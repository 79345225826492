import { StreamingPlatform } from '@typings';
import { IUser } from '../interfaces';

export interface IChannel {
    name?: string;
    link?: string;
    donationAlertsUsername?: string;
    donationAlertsUserId?: number;
    twitchLink?: string;
    youtubeLink?: string;
    telegramLink?: string;
    altTelegramLink?: string;
    mainStreamingPlatform?: StreamingPlatform;
    maLinkInstalledAt?: number;
    lastStreamAt?: number;
    lastStickerAt?: number;
    manager?: Omit<IUser, 'channel' | '_id'>;
}

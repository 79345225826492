import React from 'react';
import cn from 'classnames';
import s from './Tab.scss';
import { ComponentColor } from '@components';
import { noop } from '@common';

interface TabProps {
    active?: boolean;
    caption: string;
    color?: ComponentColor;
    onClick?: () => void;
}

export const Tab = (props: TabProps) => {
    const {
        active = false,
        caption,
        color = ComponentColor.ACCENT,
        onClick = noop,
    } = props;

    const tabClasses = cn({
        [s.tab]: true,
        [s.tabActive]: active,
        [s[color]]: true,
    });

    const tabCaptionClasses = cn({
        [s.tabCaption]: true,
        [s.tabCaptionActive]: active,
        [s[color]]: true,
    });

    return (
        <div className={tabClasses} onClick={onClick}>
            <div className={tabCaptionClasses}>{caption}</div>
        </div>
    )
}
import { Column, Row } from '@components';
import React from 'react';

import { useScoutsStatistics } from '@hooks/workbench/Streamers';
import s from './PersonalStatistics.scss';

export const PersonalStatistics = () => {
    const {
        currentScoutStatistic,
    } = useScoutsStatistics();

    return (
        <Column className={s.personalStatistic} noPadding>
            <Row className={s.header} noPadding>{'Моя статистика скаута'}</Row>
            <Row className={s.body} noPadding>
                <Column className={s.bodyContent} noPadding>
                    <Row className={s.subheader} noPadding>{'Всего'}</Row>
                    <Row noPadding>{`Активных: ${currentScoutStatistic?.total.active ?? 0}`}</Row>
                    <Row noPadding>{`В процессе: ${currentScoutStatistic?.total.inProgress ?? 0}`}</Row>
                    <Row className={s.subheader} noPadding>{'За прошлый месяц'}</Row>
                    <Row noPadding>{`Активных: ${currentScoutStatistic?.lastMonth.active ?? 0}`}</Row>
                    <Row noPadding>{`В процессе: ${currentScoutStatistic?.lastMonth.inProgress ?? 0}`}</Row>
                    <Row className={s.subheader} noPadding>{'В этом месяце'}</Row>
                    <Row noPadding>{`Активных: ${currentScoutStatistic?.thisMonth.active ?? 0}`}</Row>
                    <Row noPadding>{`В процессе: ${currentScoutStatistic?.thisMonth.inProgress ?? 0}`}</Row>
                    <Row className={s.subheader} noPadding>{'За прошлую неделю'}</Row>
                    <Row noPadding>{`Активных: ${currentScoutStatistic?.lastWeek.active ?? 0}`}</Row>
                    <Row noPadding>{`В процессе: ${currentScoutStatistic?.lastWeek.inProgress ?? 0}`}</Row>
                    <Row className={s.subheader} noPadding>{'На этой неделе'}</Row>
                    <Row noPadding>{`Активных: ${currentScoutStatistic?.thisWeek.active ?? 0}`}</Row>
                    <Row noPadding>{`В процессе: ${currentScoutStatistic?.thisWeek.inProgress ?? 0}`}</Row>
                </Column>
            </Row>
        </Column>
    )
}
import React from 'react'

import s from './SearchBar.scss';
import { Column, Input, InputValueType } from '@components';
import { WorkbenchHookReturnType } from '@hooks';
import { noop } from '@common';
import { IconSearchLine } from '@images/svg';

interface SearchBarProps {
    onChange?: (val: InputValueType) => void;
}

export const SearchBar = (props: SearchBarProps) => {
    const {
        onChange = noop,
    } = props;

    return (
        <Input
            className={s.input}
            debounceTimeMs={800}
            placeholder={'Поиск стримера'}
            tag={<IconSearchLine/>}
            onChange={onChange}
        />
    )
}
import { Logger, LoggerParams, LogLevel } from '@common/classes';
import { useEffect, useRef } from 'react';

export const LoggerColor = {
    MAGENTA: '#ff0088',
    RED: '#ff3300',
    ORANGE: '#ff9900',
    YELLOW: '#ffdd00',
    GREEN: '#00ff33',
    BLUE: '#0099ff',
    PURPLE: '#cc00ff',
}

export const useLogger = (params: LoggerParams = {}) => {
    const logger = useRef<Logger>();

    /**
     * The method writes a trace message to the log.
     *
     * @param message Trace message,
     */
    const trace = (message: any, customColor?: string) => {
        logger.current?.trace(message, customColor);
    }

    /**
     * The method writes a debug message to the log.
     *
     * @param message Debug message,
     */
    const debug = (message: any, customColor?: string) => {
        logger.current?.debug(message, customColor);
    }

    /**
     * The method writes an info message to the log.
     *
     * @param message Info message,
     */
    const info = (message: any, customColor?: string) => {
        logger.current?.info(message, customColor);
    }

    /**
     * The method writes a warning message to the log.
     *
     * @param message Warning message,
     */
    const warning = (message: any, customColor?: string) => {
        logger.current?.warning(message, customColor);
    }

    /**
     * The method writes an error message to the log.
     *
     * @param message Error message,
     */
    const error = (message: any, customColor?: string) => {
        logger.current?.error(message, customColor);
    }

    useEffect(() => {
        logger.current = new Logger(params);
    }, []);

    return {
        trace,
        debug,
        info,
        warning,
        error,
    }
}
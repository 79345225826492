import { IStreamerBinding, IStreamerTwitchData, IStreamersListPageLoadParams } from '@interfaces';
import { LoadPageParamsWith, PageOf } from '@typings';
import { cpApi } from './cp-base.api';

export const cpInProgressStreamersApi = cpApi.injectEndpoints({
    endpoints: build => ({
        getInProgressStreamersListPage: build.query<PageOf<IStreamerTwitchData>, LoadPageParamsWith<IStreamersListPageLoadParams>>({
            query: (params: LoadPageParamsWith<IStreamersListPageLoadParams>) => ({
                url: 'streamers/page',
                method: 'POST',
                body: params,
            }),
            providesTags: ["InProgressStreamers"],
        }),
        bindInProgressStreamerToScout: build.mutation<IStreamerTwitchData, IStreamerBinding>({
            query: (params: IStreamerBinding) => ({
                url: 'streamers/bind-scout',
                method: 'POST',
                body: params,
            }),
            invalidatesTags: ["InProgressStreamers"],
        }),
        unbindInProgressStreamerFromScout: build.mutation<IStreamerTwitchData, IStreamerBinding>({
            query: (params: IStreamerBinding) => ({
                url: 'streamers/bind-scout',
                method: 'DELETE',
                body: params,
            }),
            invalidatesTags: ["InProgressStreamers"],
        }),
    })
});

import { IUser } from '@interfaces';

export interface IHistoryRecord {
    id?: string;
    streamerId: string;
    streamer?: string;
    avgViewers?: number;
    registeredAt?: number;
    maLink?: string;
    daLink?: string;
    isActive?: boolean;
    maLinkDays?: number;
    timestamp: number;
    user: IUser;
    comment: string;
    scout?: IUser;
}
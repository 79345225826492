import { Column, Row } from '@components'
import React from 'react'

export const BindingConfirmation = () => {
    return (
        <Row>
            <Column>
                {'Закрепить стримера за Вами?'}
            </Column>
        </Row>
    )
}
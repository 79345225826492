import { SortType } from '@typings/enums';

export interface IStreamersListPageLoadParams {
    onlyInProgress?: boolean;
    onlyDone?: boolean;
    query?: string;
    statusFilter?: Array<number>;
    typesFilters?: Array<number>;
    daTypesFilters?: Array<number>;
    scoutFilter?: string;
    sortField?: string;
    sortType?: SortType;
}
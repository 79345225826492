import { Column, ColumnItemsAlignment, Row, RowItemsAlignment, RowItemsJustification } from '@components';
import React from 'react';
import cn from 'classnames';

import s from './CommonStatistics.scss';
import { useScoutsStatistics } from '@hooks/workbench/Streamers';

export const CommonStatistics = () => {
    const {
        commonStatistics,
    } = useScoutsStatistics();

    return (
        !! commonStatistics
        ? <Row noPadding noColumnGap className={s.commonStatistics}>
            <Column noPadding alignment={ColumnItemsAlignment.CENTER} className={cn(s.commonStatisticsColumn, s.bold)}>
                <Row noPadding justification={RowItemsJustification.CENTER}>
                    {`Всего`}
                </Row>
                <Row noPadding justification={RowItemsJustification.CENTER}>
                    {commonStatistics.total}
                </Row>
            </Column>
            <Column noPadding alignment={ColumnItemsAlignment.CENTER} className={s.commonStatisticsColumn}>
                <Row noPadding noColumnGap className={s.commonStatisticsRow}>
                    <Column noPadding className={s.kindColumn}>
                        <Row noPadding className={cn(s.kind, s.kindUnregistered)}>
                            {'Unreg'}
                        </Row>
                        <Row noPadding className={s.kind}>
                            {commonStatistics.unregistered}
                        </Row>
                        <Row noPadding className={s.kind}>
                            {(commonStatistics.unregistered / commonStatistics.total * 100).toFixed(1)}%
                        </Row>
                    </Column>
                    <Column noPadding className={s.kindColumn}>
                        <Row noPadding className={cn(s.kind, s.kindNonactive)}>
                            {'Nonact'}
                        </Row>
                        <Row noPadding className={s.kind}>
                            {commonStatistics.nonactive}
                        </Row>
                        <Row noPadding className={s.kind}>
                            {(commonStatistics.nonactive / commonStatistics.total * 100).toFixed(1)}%
                        </Row>
                    </Column>
                    <Column noPadding className={s.kindColumn}>
                        <Row noPadding className={cn(s.kind, s.kindActive)}>
                            {'Active'}
                        </Row>
                        <Row noPadding className={s.kind}>
                            {commonStatistics.active}
                        </Row>
                        <Row noPadding className={s.kind}>
                            {(commonStatistics.active / commonStatistics.total * 100).toFixed(1)}%
                        </Row>
                    </Column>
                    <Column noPadding className={s.kindColumn}>
                        <Row noPadding className={cn(s.kind, s.kindLost)}>
                            {'Lost'}
                        </Row>
                        <Row noPadding className={s.kind}>
                            {commonStatistics.lost}
                        </Row>
                        <Row noPadding className={s.kind}>
                            {(commonStatistics.lost / commonStatistics.total * 100).toFixed(1)}%
                        </Row>
                    </Column>

                    <Column noPadding className={s.kindColumn}>
                        <Row noPadding className={s.kind}>
                            {'🐳'}
                        </Row>
                        <Row noPadding className={s.kind}>
                            {commonStatistics.bigActive}
                        </Row>
                        <Row noPadding className={s.kind}>
                            {(commonStatistics.bigActive / commonStatistics.big * 100).toFixed(1)}%
                        </Row>
                    </Column>
                    <Column noPadding className={s.kindColumn}>
                        <Row noPadding className={s.kind}>
                            {'🦁'}
                        </Row>
                        <Row noPadding className={s.kind}>
                            {commonStatistics.mediumActive}
                        </Row>
                        <Row noPadding className={s.kind}>
                            {(commonStatistics.mediumActive / commonStatistics.medium * 100).toFixed(1)}%
                        </Row>
                    </Column>
                    <Column noPadding className={s.kindColumn}>
                        <Row noPadding className={s.kind}>
                            {'🐼'}
                        </Row>
                        <Row noPadding className={s.kind}>
                            {commonStatistics.smallActive}
                        </Row>
                        <Row noPadding className={s.kind}>
                            {(commonStatistics.smallActive / commonStatistics.small * 100).toFixed(1)}%
                        </Row>
                    </Column>
                    <Column noPadding className={s.kindColumn}>
                        <Row noPadding className={s.kind}>
                            {'🐁'}
                        </Row>
                        <Row noPadding className={s.kind}>
                            {commonStatistics.tinyActive}
                        </Row>
                        <Row noPadding className={s.kind}>
                            {(commonStatistics.tinyActive / commonStatistics.tiny * 100).toFixed(1)}%
                        </Row>
                    </Column>

                    {/* <Column noPadding className={s.kindColumn}>
                        <Row noPadding className={cn(s.kind, s.kindActive)}>
                            {`Active: ${commonStatistics.active} / ${(commonStatistics.active / commonStatistics.total * 100).toFixed(1)}%`}
                        </Row>
                        <Row noPadding className={cn(s.kind, s.kindLost)}>
                            {`Lost: ${commonStatistics.lost} / ${(commonStatistics.lost / commonStatistics.total * 100).toFixed(1)}%`}
                        </Row>
                    </Column> */}
                </Row>
            </Column>
        </Row>
        : null
    )
}
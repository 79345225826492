import React from 'react';
import { useInput } from './hooks/Input';

import cn from 'classnames';

import s from './Input.scss';

export type InputValueType = string | ReadonlyArray<string> | number | undefined;

export enum InputType {
    TEXT = 'text',
    PASSWORD = 'password',
    NUMBER = 'number',
    FILE = 'file',
}

interface InputProps {
    action?: JSX.Element;
    caption?: string | JSX.Element;
    className?: string;
    debounceTimeMs?: number;
    initialValue?: InputValueType;
    isRequired?: boolean;
    placeholder?: string;
    tag?: JSX.Element;
    type?: InputType;
    onAction?: () => void;
    onChange?: (value: InputValueType) => void;
}

export const Input = (props: InputProps) => {
    const {
        action,
        caption,
        className,
        placeholder,
        tag,
        type = InputType.TEXT,
        onAction,
    } = props;

    const input = useInput({ ...props });

    return (
        <div className={cn(s.input, className)}>
            {!!caption &&
                <div className={s.inputLabel}>
                    {typeof caption === 'string'
                      ? <div className={s.inputLabelCaption}>{caption}</div>
                      : caption
                    }
                    {/* <div className={s.inputLabelError}>{}</div> */}
                </div>
            }
            <div className={s.inputControl}>
                { tag &&
                    <div className={s.inputControlTag}>
                        <div className={s.inputControlTagIcon}>
                            {tag}
                        </div>
                    </div>
                }
                <input
                    className={cn(
                        s.inputControlInput,
                        {
                            [s.inputControlInputRightBorder]: !action,
                        }
                    )}
                    placeholder={placeholder}
                    type={type}

                    {...input}
                />
                {
                    action &&
                    <div
                        className={s.inputControlAction}
                        onClick={onAction}
                    >
                        <div className={s.inputControlActionIcon}>
                            {action}
                        </div>
                    </div>
                }
            </div>
        </div>
    )
}
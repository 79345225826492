import { MAConnectionType } from '@typings';
import { IStreamerHistoryRecord } from './IStreamerHistoryRecord';
import { IStreamerStatus } from './IStreamerStatus';

/** Common streamer data */
export interface IStreamerTwitchData extends IStreamerStatus {
    /** Unique ID in the DB */
    id?: string;

    /** Position in Top-1000 russian streamers */
    position: number;

    /** Streamer name (As parsed, e.g. can be uppercase) */
    name: string;

    /** Average viewers over the last 30 days */
    averageViewers: number;

    /** Time (hours) that is streamed by the streamer */
    timeStreamed: number;

    /** Maximum number of viewers on the stream */
    allTimePeakViewers: number;

    /** Total hours of streams watched by viewers */
    hoursWatched: number;

    /** The rank in the table of world streamers */
    rank: number;

    /** Followers gained during last month */
    followersGained: number;

    /** Total followers gained */
    totalFollowers: number;

    /** The streamer is banned on the Twitch platform */
    isBanned: boolean;

    /** Meme Alerts connection flag: 0 - Not connected, 1 - Connected, 2 - Connected, but link is not installed */
    isMAConnected: MAConnectionType;

    /** Donation Alerts connection flag: true - Connected, false - Not connected */
    isDAConnected: boolean;

    /** Donation Alerts username that is used in the link */
    daLink: string;

    /** Donation Alerts username that is used in the link */
    maLink: string;

    /** Meme Alerts username that is used in the link (Found in the database) */
    maLinkFromDb: string;

    /** Unix timestamp of streamer registration in MA DB */
    registeredAt: number;

    /** Unix timestamp of streamer data was parsed from twitch tracker for the first time*/
    createdAt: number;

    /** Unix timestamp of streamer data was updated from twitch tracker */
    updatedAt: number;

    /** History of communication messages */
    history?: Array<IStreamerHistoryRecord>;

    /** History of status changes */
    statusHistory: Array<IStreamerStatus>;

    /** DA Top */
    daTopPlace?: number;
}

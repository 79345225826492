import { Badge, Column, ComponentColor, Row, ScrollableArea } from '@components';
import { StreamerHistoryCommentTypeIcons, useCurrentUser, useLogger, useModals } from '@hooks';
import React, { useEffect, useRef } from 'react';

import { ImageDaLogo, ImageMaLogo } from '@assets/images/svg';
import { daysBetweenTimstamps, getAvgViewersStatus } from '@common';
import { DDMMYY, RU } from '@const';
import { useInProgressStreamers } from '@hooks/workbench/Streamers';
import { IStreamerTwitchData } from '@interfaces';
import { SortType, SortableField } from '@typings';
import cn from 'classnames';
import { Paginator, SortIcon } from '../../components';
import { ContactHistory, FreeingConfirmation } from '../../modals';
import s from './InProgress.scss';

export const InProgress = () => {
    const logger = useLogger({ target: InProgress.name });

    const { showModal, closeModal } = useModals();

    const {
        selectedStreamer,
        streamers,
        page,
        pagesCount,
        sortField,
        sortType,
        deselectStreamer,
        selectStreamer,
        selectPage,
        setSortField,
        setSortType,
        unbindStreamerFromScout,
    } = useInProgressStreamers();

    const {
        currentUser,
    } = useCurrentUser();

    const selectedStreamerRef = useRef(selectedStreamer);

    const getLastContactDateTime = (streamer: IStreamerTwitchData) => {
        if (streamer.history?.length) {
            const record = streamer.history[0];
            const icon = StreamerHistoryCommentTypeIcons[record.type];
            const source = record.type < 5 || record.type === 9 || record.type === 11
                ? record.scout?.username
                    ? `[${record.scout?.username}]`
                    : record.user?.username
                        ? `[${record.user?.username}]`
                        : ''
                : '[Событие]';

            const date = new Date(record.timestamp).toLocaleDateString(RU, DDMMYY);
            return `${date} ${icon} ${source} `;
        } else {
            return '';
        }
    }

    const getLastComment = (streamer: IStreamerTwitchData) => {
        return streamer.history?.length
            ? streamer.history[0].comment
            : '-';
    }

    const showContactHistory = (streamer: IStreamerTwitchData) => {
        showModal({
            content: <ContactHistory streamer={streamer} />,
            options: {
                bodyClassName: s.contactHistoryModalBody,
                preventCloseOnShadow: false,
            }
        })
    }

    const showFreeingConfirmation = (streamer: IStreamerTwitchData) => {
        selectStreamer(streamer);
        showModal({
            content: <FreeingConfirmation/>,
            options: {
                withHeader: true,
                caption: `Внимание ${currentUser?.username}`,
                bodyClassName: s.bindingConfirmation,
                buttons: [
                    { caption: 'Да', onClick: () => okFreeingConfirmation() },
                    { caption: 'Нет', onClick: () => cancelFreeingConfirmation(), color: ComponentColor.WHITE }
                ]
            }
        })
    }

    const okFreeingConfirmation = () => {
        if (currentUser?.id && selectedStreamerRef.current?.id) {
            unbindStreamerFromScout({
                streamerId: selectedStreamerRef.current.id,
                scoutId: currentUser?.id,
            });
        }
        closeModal();
    }

    const cancelFreeingConfirmation = () => {
        deselectStreamer();
        closeModal();
    }

    const onSortableFieldHeaderClick = (field: SortableField) => {
        if (sortField === field) {
            setSortType(prev => prev * -1);
        } else {
            setSortField(field);
            setSortType(SortType.DESC);
        }
    }

    const tableRows = streamers.map((streamer, idx) => (
        <tr
            className={cn(
                s.tableRow,
                { [s.green]: streamer.isActive },
                { [s.yellow]: streamer.isMAConnected && !streamer.becameActiveAt},
                { [s.red]: !!streamer.becameActiveAt && !streamer.isActive },
            )}
            key={streamer.id}
            onClick={() => showContactHistory(streamer)}
        >
            <td className={s.tableRowCell}>{(page - 1) * 100 + idx + 1}</td>
            <td className={s.tableRowCell} onClick={(e) => e.stopPropagation()}>
                <a
                    className={s.link}
                    href={`https://twitchtracker.com/${streamer.name.toLowerCase()}`}
                    target='_blank'
                >
                    {streamer.name}
                </a>
            </td>
            <td className={s.tableRowCell}>{streamer.averageViewers}</td>
            <td className={s.tableRowCell} onClick={(e) => e.stopPropagation()}>
                <a
                    className={s.link}
                    href={`https://twitch.tv/${streamer.name.toLowerCase()}`}
                    target='_blank'
                >
                    {getAvgViewersStatus(streamer)}
                </a>

            </td>
            <td className={s.tableRowCell} onClick={(e) => e.stopPropagation()}>
                {!!streamer.daLink &&
                    <a
                        className={s.link}
                        href={`https://donationalerts.com/r/${streamer.daLink.toLowerCase()}`}
                        target='_blank'
                    >
                        <div className={s.logo}><ImageDaLogo/></div>
                    </a>
                }
            </td>
            <td className={s.tableRowCell} onClick={(e) => e.stopPropagation()}>
                {!!streamer.maLink &&
                    <a
                        className={s.link}
                        href={`https://memealerts.com/${streamer.maLink.toLowerCase()}`}
                        target='_blank'
                    >
                        <div className={s.logo}><ImageMaLogo/></div>
                    </a>
                }
            </td>
            <td className={s.tableRowCell}>{streamer.registeredAt ? new Date(streamer.registeredAt).toLocaleDateString(RU, DDMMYY) : ''}</td>
            <td className={s.tableRowCell}>{streamer.becameActiveAt ? new Date(streamer.becameActiveAt).toLocaleDateString(RU, DDMMYY) : ''}</td>
            <td className={s.tableRowCell}>{streamer.becameActiveAt && !streamer.isActive ? new Date(streamer.becameInactiveAt).toLocaleDateString(RU, DDMMYY) : ''}</td>
            <td className={s.tableRowCell}>{getLastContactDateTime(streamer)}{getLastComment(streamer)}</td>
            <td className={s.tableRowCell}>
                <Badge caption='Contact' color={ComponentColor.WHITE} onClick={() => showContactHistory(streamer)} />
            </td>
            <td className={s.tableRowCell} onClick={(e) => e.stopPropagation()}>
                {streamer?.scout && streamer?.scout?.username === currentUser?.username &&
                    <Badge caption='Stop' color={ComponentColor.DANGER} onClick={() => showFreeingConfirmation(streamer)}/>
                }
            </td>
        </tr>
    ));

    useEffect(() => {
        selectedStreamerRef.current = selectedStreamer;
    }, [selectedStreamer]);

    return (
        <>
            <Row noPadding className={s.topBar}>
                <Column grow noPadding>
                </Column>
                <Column noPadding>
                    <Paginator
                        pagesCount={pagesCount}
                        activePage={page}
                        onPageSelect={selectPage}
                    />
                </Column>
            </Row>
            <ScrollableArea>
                <table className={s.table}>
                    <thead>
                        <tr className={s.tableHeader}>
                            <td className={s.tableHeaderCell}>
                                {'#'}
                            </td>
                            <td className={s.tableHeaderCell}>
                                {'Streamer'}
                            </td>
                            <td className={s.tableHeaderCell}>
                                <div className={s.tableHeaderCellSortable} onClick={() => onSortableFieldHeaderClick('averageViewers')}>
                                    {'Avg Viewers'}
                                    {sortField === 'averageViewers' && <SortIcon sortType={sortType} />}
                                </div>
                            </td>
                            <td className={s.tableHeaderCell}>
                                {'Type'}
                            </td>
                            <td className={s.tableHeaderCell}>
                                {'DA'}
                            </td>
                            <td className={s.tableHeaderCell}>
                                {'MA'}
                            </td>
                            <td className={s.tableHeaderCell}>
                                <div className={s.tableHeaderCellSortable} onClick={() => onSortableFieldHeaderClick('registeredAt')}>
                                    {'Registered'}
                                    {sortField === 'registeredAt' && <SortIcon sortType={sortType} />}
                                </div>
                            </td>
                            <td className={s.tableHeaderCell}>
                                <div className={s.tableHeaderCellSortable} onClick={() => onSortableFieldHeaderClick('becameActiveAt')}>
                                    {'Join'}
                                    {sortField === 'becameActiveAt' && <SortIcon sortType={sortType} />}
                                </div>
                            </td>
                            <td className={s.tableHeaderCell}>
                                <div className={s.tableHeaderCellSortable} onClick={() => onSortableFieldHeaderClick('becameInactiveAt')}>
                                    {'Lost'}
                                    {sortField === 'becameInactiveAt' && <SortIcon sortType={sortType} />}
                                </div>
                            </td>
                            <td className={s.tableHeaderCell}>
                                {'Comment'}
                            </td>
                            <td className={s.tableHeaderCell}>
                                {'Contact'}
                            </td>
                            <td className={s.tableHeaderCell}>
                                {'Scout'}
                            </td>
                        </tr>
                    </thead>
                    <tbody>
                        {tableRows}
                    </tbody>
                </table>
            </ScrollableArea>
        </>
    )
}

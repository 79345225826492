import { Column, Row } from '@components'
import React from 'react'

interface FreeingConfirmationProps {
    text?: string;
}

export const FreeingConfirmation = (props: FreeingConfirmationProps) => {
    const {
        text = 'Вы действительно хотите открепить стримера?',
    } = props;
    return (
        <Row>
            <Column>
                {text}
            </Column>
        </Row>
    )
}
import React from 'react'
import cn from 'classnames';

import s from './Button.scss';
import { ComponentColor } from '@components';

export enum ButtonType {
    BUTTON = 'button',
    SUBMIT = 'submit',
    RESET = 'reset',
}

interface ButtonProps {
    caption?: string;
    color?: ComponentColor;
    disabled?: boolean;
    icon?: JSX.Element;
    type?: ButtonType;
    onClick?: () => void;
}

export const Button = (props: ButtonProps) => {
    const {
        caption,
        color = ComponentColor.ACCENT,
        disabled = false,
        icon,
        type = ButtonType.BUTTON,
        onClick,
    } = props;

    return (
        <button
            className={cn({
                [s.button]: true,
                [s.buttonClickable]: !!onClick,
                [s[color]]: true,
                [s[color + 'Disabled']]: disabled,
            })}
            type={type}
            onClick={onClick}
        >
            { !!icon &&
                <div className={s.buttonIcon}>
                    { icon }
                </div>
            }
            { !!caption &&
                <div className={s.buttonCaption}>
                    { caption }
                </div>
            }
        </button>
    )
}
import cn from 'classnames';
import { ComponentColor } from '@components';
import React from 'react'
import { TextValueType, useText } from './hooks';

import s from './TextArea.scss';

interface TextAreaProps {
    className?: string;
    readonly?: boolean;
    placeholder?: string;
    maxRows?: number;
    maxLength?: number;
    defaultValue?: string;
    debounceTime?: number;
    color?: ComponentColor;
    value?: TextValueType;
    onChange?: (value: TextValueType) => void;
}

export const TextArea = (props: TextAreaProps) => {
    const {
        className,
        placeholder,
        maxRows = 15,
        maxLength,
        color = ComponentColor.ACCENT,
    } = props;

    const text = useText({...props});

    const textClassNames = cn(
        s.text,
        className,
        s['color' + color],
    );

    return (
        <div className={textClassNames}>
            <textarea
                rows={maxRows}
                placeholder={placeholder}
                maxLength={maxLength}
                {...text}
            />
        </div>
    )
}
import React from 'react'
import cn from 'classnames';

import s from './ToolBar.scss';
import { ToolBarButton, ToolBarButtonProps } from './components';
import { ToolBarElementKind } from './components/common';

type ToolBarElement = ToolBarButtonProps;

interface ToolBarProps {
    elements?: Array<ToolBarElement>;
}

export const ToolBar = (props: React.PropsWithChildren<ToolBarProps>) => {
    const {
        children,
        elements,
    } = props;

    const items = elements?.map((element, idx) => {
        switch (element.kind) {
            case ToolBarElementKind.BUTTON:
                return <ToolBarButton {...element} key={idx} />
        }
    });

    return (
        <div className={s.toolbar}>
            {children}
            {items}
        </div>
    );
};
import { useLogger, useWorkbench } from '@hooks';
import { BaseLayout } from '@layouts';
import { Header } from '@views/fragments';
import React from 'react';
import { Outlet } from 'react-router-dom';

export const Workbench = () => {
    const logger = useLogger({ target: Workbench.name });
    const workbench = useWorkbench();

    return (
        <BaseLayout header={<Header {...workbench}/>}>
            <Outlet context={workbench}/>
        </BaseLayout>
    );
}
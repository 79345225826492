import React from 'react'
import cn from 'classnames';
import { MenuItem, MenuItemProps } from './MenuItem';

import s from './Menu.scss';

export enum MenuOrientation {
    HORIZONTAL = 'orientationHorizontal',
    VERTICAL = 'orientationVertical',
}

interface MenuProps {
    elements: Array<MenuItemProps>;
    orientation?: MenuOrientation;
}

export const Menu = (props: MenuProps) => {
    const {
        elements = [],
        orientation = MenuOrientation.HORIZONTAL,
    } = props;

    return (
        <div className={cn({
            [s.menu]: true,
            [s[orientation]]: true,
        })}>
            {
                elements.map((menuItemConfig, i) => (
                    <MenuItem key={i} {...menuItemConfig} parentOrientation={orientation} />
                ))
            }
        </div>
    );
};

import { useApi } from '@hooks/webapi';
import { IStreamerTwitchData } from '@interfaces';
import { SortType, SortableField } from '@typings';
import { useEffect, useState } from 'react';

const PAGE_SIZE = 100;

export const useInProgressStreamers = () => {
    const {
        inProgressStreamers: {
            useLazyGetInProgressStreamersListPageQuery,
            useBindInProgressStreamerToScoutMutation,
            useUnbindInProgressStreamerFromScoutMutation,
        }
    } = useApi();

    const [ loadPage, { data: loadedPage, isFetching } ] = useLazyGetInProgressStreamersListPageQuery();
    const [ bindStreamerToScout ] = useBindInProgressStreamerToScoutMutation();
    const [ unbindStreamerFromScout ] = useUnbindInProgressStreamerFromScoutMutation();

    const [ streamers, setStreamers ] = useState<IStreamerTwitchData[]>([]);
    const [ selectedStreamer, setSelectedStreamer ] = useState<IStreamerTwitchData | null>(null)
    const [ totalCount, setTotalCount ] = useState(0);
    const [ pagesCount, setPagesCount ] = useState(0);
    const [ page, setPage ] = useState(1);
    const [ sortField, setSortField ] = useState<SortableField>('averageViewers');
    const [ sortType, setSortType ] = useState<SortType>(SortType.DESC);

    const selectPage = (page: number)  => setPage(page);
    const selectStreamer = (streamer: IStreamerTwitchData) => setSelectedStreamer(streamer);
    const deselectStreamer = () => setSelectedStreamer(null);

    useEffect(() => {
        loadPage({
            onlyInProgress: true,
            skip: PAGE_SIZE * (page - 1),
            limit: PAGE_SIZE,
            sortField,
            sortType,
        });
    }, [page]);

    useEffect(() => {
        if (page !== 1) {
            setPage(1);
        } else {
            loadPage({
                onlyInProgress: true,
                skip: PAGE_SIZE * (page - 1),
                limit: PAGE_SIZE,
                sortField,
                sortType,
            });
        }
    }, [sortField, sortType]);

    useEffect(() => {
        if (loadedPage) {
            setStreamers(loadedPage.data);
            setTotalCount(loadedPage.total);
            setPagesCount(Math.ceil(loadedPage.total / 100));
        }
    }, [loadedPage]);

    return {
        selectedStreamer,
        streamers,
        page,
        pagesCount,
        sortField,
        sortType,
        totalCount,
        bindStreamerToScout,
        deselectStreamer,
        selectPage,
        selectStreamer,
        setSortField,
        setSortType,
        unbindStreamerFromScout,
    }
}
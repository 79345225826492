import { useApi } from '@hooks/webapi';

export const useScoutPayout = () => {
    const {
        streamers: {
            useGetPayoutSummaryQuery,
        }
    } = useApi();

    const { data: payoutSummary } = useGetPayoutSummaryQuery();

    return {
        payoutSummary,
    }
}
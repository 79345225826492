import { useLogin } from '@hooks/login';

export type WorkbenchHookReturnType = ReturnType<typeof useWorkbench>;

export const useWorkbench = () => {
    const { logout } = useLogin();

    return {
        logout,
    }
}
import React, { createContext, useRef } from 'react';

export const AUTH_TOKEN_NAME = 'cpt';

interface AuthContextType {
    token: string;
    updateToken: (token: string) => void;
    clearToken: () => void;
}

export const AuthContext = createContext<AuthContextType>({
    token: '',
    updateToken: () => {},
    clearToken: () => {},
});

export const AuthProvider = ({ children }: { children: React.ReactNode }) => {
    const token = useRef<string>();

    token.current = localStorage.getItem(AUTH_TOKEN_NAME) ?? '';


    const updateToken = (token: string) => {
        localStorage.setItem(AUTH_TOKEN_NAME, token);
    }

    const clearToken = () => {
        localStorage.removeItem(AUTH_TOKEN_NAME);
    }

    const value = {
        token: token.current,
        updateToken,
        clearToken,
    };

    return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
}

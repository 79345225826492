import { ITrackingHistoryRecord } from '@interfaces';
import { LoadPageParams } from '@typings';
import { cpApi } from './cp-base.api';

export const cpTrackingHistoryApi = cpApi.injectEndpoints({
    endpoints: build => ({
        getTrackingHistory: build.query<Array<ITrackingHistoryRecord>, string>({
            query: (streamer: string) => ({
                url: `tracking-history/${streamer}`,
                method: 'GET',
            }),
            providesTags: ["TrackingHistory"],
        }),
        getAllTrackingHistoryPage: build.query<Array<ITrackingHistoryRecord>, LoadPageParams>({
            query: (params: LoadPageParams) => ({
                url: `tracking-history/page`,
                method: 'POST',
                body: params,
            }),
            providesTags: ["AllTrackingHistory"],
        }),
        createTrackingHistoryRecord: build.mutation<ITrackingHistoryRecord, ITrackingHistoryRecord>({
            query: (data: ITrackingHistoryRecord) => ({
                url: `tracking-history`,
                method: 'POST',
                body: data,
            }),
            invalidatesTags: ["TrackingHistory", "RegisteredStreamers"],
        }),
    })
});

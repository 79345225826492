import { cpApi } from './cp-base.api';

export const cpAdminApi = cpApi.injectEndpoints({
    endpoints: build => ({
        uploadDaTopData: build.query<any, FormData>({
            query: (data: FormData) => ({
                url: 'admin/update-top',
                method: 'POST',
                body: data,
            }),
        }),
    })
});

import { useApi } from '@hooks/webapi';
import { IStreamerTwitchData } from '@interfaces';
import { useEffect, useState } from 'react';

export const useBonuses = () => {
    const {
        doneStreamers: {
            useLazyGetDoneStreamersListPageQuery,
            useBindDoneStreamerToScoutMutation,
            useUnbindDoneStreamerFromScoutMutation,
            usePayToScoutMutation,
        }
    } = useApi();

    const [ loadPage, { data: loadedPage, isFetching } ] = useLazyGetDoneStreamersListPageQuery();
    const [ bindStreamerToScout ] = useBindDoneStreamerToScoutMutation();
    const [ unbindStreamerFromScout ] = useUnbindDoneStreamerFromScoutMutation();
    const [ payToScout ] = usePayToScoutMutation();

    const [ streamers, setStreamers ] = useState<IStreamerTwitchData[]>([]);
    const [ selectedStreamer, setSelectedStreamer ] = useState<IStreamerTwitchData | null>(null)
    const [ totalCount, setTotalCount ] = useState(0);
    const [ pagesCount, setPagesCount ] = useState(0);
    const [ page, setPage ] = useState(1);

    const selectPage = (page: number)  => setPage(page);
    const selectStreamer = (streamer: IStreamerTwitchData) => setSelectedStreamer(streamer);
    const deselectStreamer = () => setSelectedStreamer(null);

    useEffect(() => {
        loadPage({
            onlyDone: true,
            skip: 0,
            limit: 0,
        });
    }, [page]);

    useEffect(() => {
        if (loadedPage) {
            setStreamers(loadedPage.data);
            setTotalCount(loadedPage.total);
            setPagesCount(Math.ceil(loadedPage.total / 100));
        }
    }, [loadedPage]);

    return {
        selectedStreamer,
        streamers,
        page,
        pagesCount,
        totalCount,
        bindStreamerToScout,
        deselectStreamer,
        payToScout,
        selectPage,
        selectStreamer,
        unbindStreamerFromScout,
    }
}
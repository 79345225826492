import { IStreamerTwitchData } from '@typings';

const AvgViewersStatus = {
    TINY: '🐁',
    SMALL: '🐼',
    MEDIUM: '🦁',
    BIG:'🐳',
}

export const DaTopStatus = {
    VIP: 'VIP',
    HIGH: 'HIGH',
    MID: 'MID',
    LOW: 'LOW',
    NONE: 'NONE',
}

const Statuses = {
    UNREGISTERED: 'Unreg',
    NONACTIVE: 'Nonact',
    ACTIVE: 'Active',
    LOST: 'Lost',
}

export const getAvgViewersStatus = (streamer: Partial<Pick<IStreamerTwitchData, 'averageViewers'>>) => {
    if (!streamer.averageViewers) return 'X';
    if (streamer.averageViewers >= 2000) {
        return AvgViewersStatus.BIG;
    } else if (streamer.averageViewers >= 1000) {
        return AvgViewersStatus.MEDIUM;
    } else if (streamer.averageViewers >= 500) {
        return AvgViewersStatus.SMALL;
    } else {
        return AvgViewersStatus.TINY;
    }
}

export const getDaStatus = (streamer: Partial<Pick<IStreamerTwitchData, 'daTopPlace'>>) => {
    if (!streamer.daTopPlace || streamer.daTopPlace >= 999999) return '-';
    if (streamer.daTopPlace >= 5001) {
        return DaTopStatus.LOW;
    } else if (streamer.daTopPlace >= 1001) {
        return DaTopStatus.MID;
    } else if (streamer.daTopPlace >= 101) {
        return DaTopStatus.HIGH;
    } else {
        return DaTopStatus.VIP;
    }
}

export const getReward = (streamer: Partial<Pick<IStreamerTwitchData, 'daTopPlace' | 'averageViewers'>>): number => {
    const value = getDaStatus(streamer);
    const type = getAvgViewersStatus(streamer);

    switch (value) {
        case DaTopStatus.VIP:
            switch (type) {
                case AvgViewersStatus.BIG:
                    return 10000;
                case AvgViewersStatus.MEDIUM:
                    return 10000;
                case AvgViewersStatus.SMALL:
                    return 10000;
                case AvgViewersStatus.TINY:
                    return 10000;
                default:
                    return 0;
            }
            break;
        case DaTopStatus.HIGH:
            switch (type) {
                case AvgViewersStatus.BIG:
                    return 10000;
                case AvgViewersStatus.MEDIUM:
                    return 8000;
                case AvgViewersStatus.SMALL:
                    return 7000;
                case AvgViewersStatus.TINY:
                    return 4000;
                default:
                    return 0;
            }
            break;
        case DaTopStatus.MID:
            switch (type) {
                case AvgViewersStatus.BIG:
                    return 10000;
                case AvgViewersStatus.MEDIUM:
                    return 7000;
                case AvgViewersStatus.SMALL:
                    return 5000;
                case AvgViewersStatus.TINY:
                    return 2000;
                default:
                    return 0;
            }
            break;
        case DaTopStatus.LOW:
            switch (type) {
                case AvgViewersStatus.BIG:
                    return 5000;
                case AvgViewersStatus.MEDIUM:
                    return 2000;
                case AvgViewersStatus.SMALL:
                    return 500;
                case AvgViewersStatus.TINY:
                    return 250;
                default:
                    return 0;
            }
            break;
        default:
            return 0;
    }
}

export const getAvgViewersStatuses = () => {
    return [
        '🐳 Whales',
        '🦁 Lions',
        '🐼 Pandas',
        '🐁 Mouses',
    ]
}

export const getShortNumberText = (val: number)  => {
    if (val < 1000) return val.toFixed(0);
    return `${(val / 1000).toFixed(1)}K`;
}

export const getStreamerStatus = (streamer: Partial<Pick<IStreamerTwitchData, 'isActive' | 'isMAConnected' | 'becameActiveAt'>>) => {
    if (streamer.isActive) return Statuses.ACTIVE;
    if (streamer.isMAConnected && !streamer.becameActiveAt) return Statuses.NONACTIVE;
    if (!streamer.isMAConnected) return Statuses.UNREGISTERED;
    if (!streamer.isActive && streamer.becameActiveAt) return Statuses.LOST;
    return '';
}
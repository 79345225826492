import { useApi } from '@hooks/webapi';
import { IScoutStatistics, IScoutsStatisticsPageLoadResult } from '@interfaces';
import { useEffect, useState } from 'react';

const PAGE_SIZE = 20;

export const useScoutsStatistics = () => {
    const {
        scouts: {
            useLazyGetScoutsStatisticsPageQuery,
            useGetScoutStatisticsQuery,
            useGetCommonStatisticsQuery,
        }
    } = useApi();

    const { data: currentScoutStatistic } = useGetScoutStatisticsQuery();
    const { data: commonStatistics } = useGetCommonStatisticsQuery();
    const [ loadPage, { data: loadedPage }] = useLazyGetScoutsStatisticsPageQuery();

    const [ statistics, setStatistics ] = useState<Array<IScoutStatistics>>([]);
    const [ totalCount, setTotalCount ] = useState(0);
    const [ pagesCount, setPagesCount ] = useState(0);
    const [ page, setPage ] = useState(1);

    const selectPage = (page: number)  => setPage(page);

    useEffect(() => {
        loadPage({
            skip: PAGE_SIZE * (page - 1),
            limit: PAGE_SIZE,
        });
    }, [page]);

    useEffect(() => {
        setStatistics(loadedPage?.data ?? []);
        setTotalCount(loadedPage?.total ?? 0);
        setPagesCount(Math.ceil(loadedPage?.total ?? 0 / 100));
    }, [loadedPage]);

    return {
        commonStatistics,
        currentScoutStatistic,
        page,
        pagesCount,
        statistics,
        totalCount,
        selectPage,
    }
}